
import Vue from "vue";
import {ActionTree} from "vuex";
import download from "downloadjs";
import {isEmpty, isObject, template} from "lodash";

import {app} from "@/main";
import router from "@/router";
import {extname} from "@/utils";
import {fetch} from "@/services/api";
import { TOAST_OPTIONS } from "@/constants";
import {validation, validationFile} from "@/services";
import { logError } from "@/services/api/serverLogger";
import {up, openImgInNewTab, fileToBase64, base64toBlob} from "@/helpers";

import { RootState } from "../../root.interface";
import { IPreApplicationState, TUploadName, TControlName, IPayerFields, IFormControls } from "./preapplication.interfaces";

import {
    CLEAR_FORM_CONTROLS,
    CLEAR_REGIONS,
    NEXT_ACTIVE_TAB,
    SET_ACTIVE_TAB,
    SET_CHANGE_STATUS_VISIBILITY,
    SET_CREATION_DATE,
    SET_ERROR_MESSAGE,
    SET_FORM_CONTROL,
    SET_IS_EDIT,
    SET_DISABLED,
    SET_IS_SUBMITED,
    SET_IS_FORM_VALID,
    SET_MANAGERCOMMENT,
    SET_OPTIONS_COUNTRY,
    SET_OPTIONS_REGION,
    SET_ORDER_ID,
    SET_PARTNERMANAGER_ID,
    SET_FILIAL_ID,
    SET_PREAPP_ADDITIONAL_STATUSES,
    SET_PREAPP_FILIAL_COMMENT,
    SET_STATUS,
    SET_STATUS_ID,
    SET_TYPE_SELECTION,
    SET_APPLICATION_ID,
    SET_PREAPP_PAYMENT,
    SET_PREAPP_TYPE,
    SET_UPLOAD,
    SET_ANOTHER_UPLOAD,
    CLEAN_UPLOADS,
    CLEAN_ANOTHER_UPLOADS,
    SET_FNS_EXISTING_CERTS,
    DEFINE_PAYER_FIELDS,
    SET_SKZI_PREAPP,
    SET_UPD_STATUS,
    DEFINE_ADDRESS_FIELDS,
    DEFINE_LICENSE_FIELDS,
    NOT_REQUIRED_LICENSE_FIELDS,
    SET_READONLY_FIELDS_KEY_ALL,
    SET_PASSPORT_DIVISION,
    SET_OPTIONS_PASSPORT_DIVISIONS,
    CLEAR_OPTIONS_PASSPORT_DIVISIONS,
    DEFINE_TOKEN_FIELDS,
    NOT_REQUIRED_TOKEN_FIELDS,
} from "./preapplication.mutations";

import { scrollToInvalidFormItem } from "@/helpers/scrollToInvalidFormItem";
import { STATUSES, TYPE_ENTRES, IDENTIFICATION_STATUSES, PAYER_TYPES, DEFAULT_COUNTRY } from "./preapplication.constants";
import api from './preapplication.api';
import { BASIS_STAUSES } from "../application/application.constants";
import { PRODUCT_BINDS } from "../products/products.constants";
import { UpdRequestStatuses } from "@/constants/UpdRequestStatuses";
import ROLES from "@/config/roles";

const isFormValid = (controls: any) => {
    const errors: string[] = [];
    Object.keys(controls).forEach((key: string) => {
        const control = {...controls[key]};
        if (
            (!control.isBlur && control.isBlur !== undefined && control.active) ||
            (control.required && !control.isValid && control.active)
        ) {
            errors.push(key);
        }
    });

    return errors.length === 0;
};

////// преобразует имя поля payerName в name
const getPayerFieldName = (nameControl: string) => {
    const name = nameControl.replace("payer", "");
    return name.charAt(0).toLowerCase() + name.slice(1);
};

export const actions: ActionTree<IPreApplicationState, RootState> = {
    clearFromControlsHandler({commit, dispatch}: any) {
        commit(CLEAR_FORM_CONTROLS);
        commit(CLEAR_REGIONS);
        commit(SET_TYPE_SELECTION, "");
    },

    clearErrorMessage({commit}: any) {
        commit(SET_ERROR_MESSAGE, null);
    },

    clearManagerComment({commit}) {
        commit(SET_MANAGERCOMMENT, null);
    },

    clearPreAppType({commit}) {
        commit(SET_PREAPP_TYPE, 0);
    },

    setActiveTab({commit, state}: any, num: number) {
        if (num !== state.activeTab) {
            commit(SET_ACTIVE_TAB, num);
        }
    },

    nextActiveTab({commit}: any, scrollUp: boolean = false) {
        if (scrollUp) {
            up(commit.bind(null, NEXT_ACTIVE_TAB));
        } else {
            commit(NEXT_ACTIVE_TAB);
        }
    },

    typeSelectionHandler({commit, state, dispatch}: any, evt: any) {
        const innControl = {...state.formControls.inn};

        innControl.value = "";
        innControl.isValid = true;
        innControl.isBlur = false;

        if (evt.target.value === "ur") {
            innControl.rules = {
                inn: true,
                empty: true,
                minLength: 10,
            };
        } else {
            innControl.rules = {
                inn: true,
                empty: true,
                minLength: 12,
            };
        }

        commit(SET_FORM_CONTROL, {control: innControl, name: "inn"});

        commit(SET_TYPE_SELECTION, evt.target.value);

        if (
            !isEmpty(state.formControls.typeEntre.value) &&
            state.isFormValid !== null &&
            !state.isFormValid
        ) {
            dispatch("checkValidityAndScroll");
        }
    },

    checkValidity({commit, state}: any) {
        Object.keys(state.formControls).forEach((name: string) => {
            if (state.formControls[name].active && name !== "typeEntre") {
                const control = {...state.formControls[name]};
                control.isTouched = true;
                control.isBlur = true;

                const {isValid, invalidityInfo} = validation(control.value, control.rules);
                control.isValid = isValid;

                if (!isValid) {
                    control.invalidityInfo = invalidityInfo;
                }
                
                commit(SET_FORM_CONTROL, {control, name});
            }
        });

        commit(SET_IS_FORM_VALID, isFormValid(state.formControls));
    },

    inputHandler({commit, state}: any, evt: any) {
        const {name, checked, type, value} = evt.target;
        const control = {...state.formControls[name]};
        const isCheckbox = type === "checkbox";

        if (evt.type === "input" && name.startsWith("filialComment")) {
            commit(SET_PREAPP_FILIAL_COMMENT, value.replace(/\s+/g, " ").replace(/^\s/, ""));
        } else {
            if ((evt.type === "change" && name.startsWith("region")) || !name.startsWith("region")) {
                control.value = isCheckbox ? checked : value;

                if (name === "fullNameOrg" || name === "shortNameOrg") {
                    control.value = value.replaceAll("\n\r", " ").replaceAll("\r\n", " ").replace(/[\n\r]/g, " ");
                } else if (typeof control.value === "string") {
                    control.value = value.replace(/\s+/g, " ").replace(/^\s/, "");
                }
            }

            control.isTouched = true;

            if (
                (evt.type === "blur" || control.isBlur) &&
                !control.checkboxId &&
                isFormValid !== null
            ) {
                control.isBlur = true;

                const {isValid, invalidityInfo} = validation(control.value, control.rules);
                control.isValid = isValid;

                if (!isValid) {
                    control.invalidityInfo = invalidityInfo;
                }
            }
            if (type === "multiselect") {
                commit(SET_FORM_CONTROL, {control: {value: JSON.parse(JSON.stringify(control.value)), ...control}, name});
            } else {
                commit(SET_FORM_CONTROL, {control, name});
            }

            if (!state.isFormValid) {
                commit(SET_IS_FORM_VALID, isFormValid(state.formControls));
            }
        }

        if (name === "payerSubjectType" && evt.type=="change") {
            commit(DEFINE_PAYER_FIELDS, value);
        }
    },

    async getReferenceCountries({commit}) {
        try {
            const res = await fetch.post(`api/InitialApplication/getCountries`);

            if (res?.status !== 200 || !res?.data?.countries || !Array.isArray(res.data.countries)) {
                throw new Error('запрос завершился неуспешно');
            }

            commit(SET_OPTIONS_COUNTRY, res.data.countries);
        } catch (err) {
            const error = err as any;
            const errorMessage = JSON.stringify(
                error.response?.data ||
                error.message ||
                'Неизвестная ошибка'
            );
            logError(error, `Ошибка получения списка стран`);
            Vue.$toast.error(`Ошибка получения списка стран: ${errorMessage}`, TOAST_OPTIONS.Error);
        }
    },

    async getReferenceRegions({commit}) {
        try {
            const res = await fetch.post(`api/InitialApplication/getRegions`);

            if (res?.status !== 200 || !res?.data?.regions || !Array.isArray(res.data.regions)) {
                throw new Error('запрос завершился неуспешно');
            }

            commit(SET_OPTIONS_REGION, [{value: 0, label: 'Не выбрано'}, ...res.data.regions]);
        } catch (err) {
            const error = err as any;
            const errorMessage = JSON.stringify(
                error.response?.data ||
                error.message ||
                'Неизвестная ошибка'
            );
            logError(error.message, `Ошибка получения списка регионов`);
            Vue.$toast.error(`Ошибка получения списка регионов: ${errorMessage}`, TOAST_OPTIONS.Error);
        }
    },

    async fetchPreApplication({commit, dispatch, state}: any, id: number | string) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const res = await fetch.get(`api/InternalPreApplication/${id}`);
            if (app) {
                (app as any).$modal.hide("loader");
            }

            if (res.status === 200) {
                const {
                    preapplication,
                    status,
                    statusId,
                    partnerManagerId,
                    filialId,
                    creationDate,
                    managerComment,
                    additionalStatuses,
                    scans,
                    applicationId,
                    payment,
                    products,
                    payer,
                    skziPreapp,
                    error,
                } = res.data;

                if (error) {
                    throw new Error(error);
                }

                const hasInsuranceTariff = products.some(({bind}: {bind: number}) => PRODUCT_BINDS.Insurance.includes(bind));
                dispatch("initProducts", {products, productsSelected: preapplication.products}, { root: true });
                dispatch("initFields", {preapplication, payer: payer ?? {}, hasInsuranceTariff});

                commit(SET_IS_EDIT, false);
                commit(SET_IS_FORM_VALID, true);
                commit(SET_ORDER_ID, id);
                commit(SET_STATUS, preapplication.identificationStatus || status);
                commit(SET_STATUS_ID, preapplication.identificationStatusId || statusId);
                commit(SET_APPLICATION_ID, applicationId);
                commit(SET_PARTNERMANAGER_ID, partnerManagerId);
                commit(SET_FILIAL_ID, filialId);
                commit(SET_MANAGERCOMMENT, managerComment);
                commit(SET_TYPE_SELECTION, preapplication.typeEntre);
                commit(SET_CREATION_DATE, creationDate);
                commit(SET_PREAPP_ADDITIONAL_STATUSES, additionalStatuses);
                commit(SET_PREAPP_PAYMENT, payment);
                commit(SET_PREAPP_TYPE, preapplication.type);
                commit(SET_FNS_EXISTING_CERTS, preapplication.existingCertsInFNS || []);
                commit(SET_SKZI_PREAPP, skziPreapp);
                commit(SET_UPD_STATUS, preapplication.updStatus);

                dispatch("initUploads", {scans});
            }
        } catch (err) {
            const error = (err as any);
            if (error.response?.status === 403) {
                router.push("/403");
            } else {
                const errorMessage = JSON.stringify(error.response?.data || error.message || 'Неизвстная ошибка');
                Vue.$toast.error(`Ошибка загрузки данных предзаявки ${id}: ${errorMessage}`, TOAST_OPTIONS.Error);
                logError(error, `Ошибка загрузки данных предзаявки ${id}: ${errorMessage}`);
            }
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }
    },

    /*
        инициализирует документы, которые буду отображаться во вкладке Документы (клиент)
    */
    initUploads({state, commit}, {scans}) {   
        Object.keys(state.uploads).forEach((name: any) => {
            const upload: any = {...state.uploads[name as TUploadName]};
            const scan = scans.find((scan: any) => scan.fileType === upload.fileType);
            if (scan) {
                commit(SET_UPLOAD, {
                    name,
                    upload: {
                        ...upload,
                        guid: scan.id,
                        fileType: scan.fileType,
                        header: scan.header,
                        isValid: true,
                        isWatchable: true
                    }
                })
            }
        });
    },

    initFields({commit, state}: any, {preapplication, payer}: any) {
        Object.entries(state.formControls as IFormControls).forEach(([nameControl, controlObj]) => {
            const control = {...controlObj};

            if (nameControl !== "typeEntre" && !nameControl.startsWith('separatedAddress')) {
                const val: any = !nameControl.startsWith("payer")
                    ? preapplication[nameControl]
                    : payer[getPayerFieldName(nameControl)];

                if (isObject(val)) {
                    Object.keys(val).forEach((name) => {
                        if (control.hasOwnProperty(name)) {
                            control[name] = (val as any)[name];
                        }
                    });
                } else if (val !== null) {
                    if (nameControl == "payerSubjectType") {
                        // плательщик "Сам заявитель" по-умолчанию
                        const payerSubjectType = Object.values(PAYER_TYPES).find(type => type.id === val);
                        control.value =  payerSubjectType ? payerSubjectType.value : PAYER_TYPES.himself.value;
                    } else {
                        control.value = val === undefined ? control.value : val;
                    }
                }

                if (
                    TYPE_ENTRES.find(({id}) => id === preapplication['applicantTypeId'])
                    ?.notRequiredFields.includes(nameControl)
                ) {
                    control.required = false;
                }

                control.rules = {...control.rules, empty: control.required};

                control.disabled = true;
                control.isValid = true;
                control.isBlur = true;
                commit(SET_FORM_CONTROL, {control, name: nameControl});
            } else if (nameControl.startsWith('separatedAddress')) {
                const addressObjectName =
                    preapplication.typeEntre === 'ur'
                        ? 'legalAddressObject'
                        : 'actualAddressObject';
                let actualControlName = nameControl
                    .replace('separatedAddress', '')
                    .replace('Type', '')
                    .replace('Value', '')
                actualControlName = actualControlName.replace(actualControlName[0], actualControlName[0].toLowerCase());
                const valueOrTypeMatch = nameControl.match(/Value|Type/);
                let valueOrType;
                if (valueOrTypeMatch) {
                    valueOrType = valueOrTypeMatch[0].replace(valueOrTypeMatch[0], valueOrTypeMatch[0].toLowerCase());
                }
                const val = 
                    preapplication[addressObjectName] && preapplication[addressObjectName][actualControlName]
                    ? valueOrType
                        ? preapplication[addressObjectName][actualControlName][valueOrType]
                        : preapplication[addressObjectName][actualControlName]
                    :  undefined;
                control.value = val === undefined ? control.value : val;
                control.disabled = true;
                control.isValid = true;
                control.isBlur = true;
                commit(SET_FORM_CONTROL, {control, name: nameControl});
            }
        });

        commit(DEFINE_PAYER_FIELDS, state.formControls.payerSubjectType.value);

        // also look for defineAddressFields(), it's triggered in Preapplication
    },

    async createApplication({commit, dispatch, state}: any) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const res = await fetch.post(`api/InternalPreApplication/create/${state.orderId}`);
            if (app) {
                (app as any).$modal.hide("loader");
            }

            if (res.status === 200) {
                const {orderId} = res.data;
                if (!res.data.hasOwnProperty("error")) {
                    if (orderId) {
                        router.push(`/application/${orderId}`);
                    } else {
                        throw new Error("Что-то, пошло не так");
                    }
                } else {
                    // throw new Error(res.data.error);
                    let mess = `Ошибка при создании заявки из предзаявки ${state.orderId}: ${res.data.error}`;

                    Vue.$toast.error(mess, TOAST_OPTIONS.Error);
                }
            }
        } catch (err) {
            const error = (err as any);
            if (error.response.status === 403) {
                if (app) {
                    (app as any).$modal.hide("loader");
                }
                router.push("/403");
            } else {
                logError(
                    error,
                    `Ошибка генерации заявки на выпуск для предзаявки ${
                        state.orderId
                    }`
                );
                let mess = `Ошибка при сохранении заявки ${state.orderId}`;

                if (error.data && error.data.message) {
                    mess = error.data.message;
                }
                Vue.$toast.error(mess, TOAST_OPTIONS.Error);
            }
        }
    },

    editPreApp({commit, state, rootGetters}: any) {
        if (state.activeTab === 1 || state.activeTab === 2) {
            const role: ROLES = rootGetters["authorizationState/getRole"];
            
            if (role === ROLES.ManagerPartner) {
                const currentApplicant = state.formControls.typeEntre.value;
                const fields = TYPE_ENTRES.find(({name}) => name === currentApplicant)?.inputFields;
                const readOnlyFields = fields?.filter((value) => value !== "payerSubjectType");
                
                commit(SET_READONLY_FIELDS_KEY_ALL, readOnlyFields);
            }

            commit(SET_IS_EDIT, true);
            commit(SET_DISABLED, false);
            commit(SET_IS_FORM_VALID, true);
        }

        commit(SET_IS_SUBMITED, false);
        
    },

    async submitHandler({commit, state, dispatch, getters}: any) {
        // Валидация отключена в рамках задачи 27
        // dispatch("checkValidity");

        const isExtraPayer = state.formControls["payerSubjectType"].value !== PAYER_TYPES.himself.value;

        // if (state.isFormValid) {
            const body: any = {
                orderId: Number(state.orderId),
                products: await dispatch("getProductsSelected", null, {root: true}),
                filialId: Number(state.filialId),
                snils: String(state.snils),
                payer: isExtraPayer ? {} : null,
            };

            Object.keys(state.formControls).forEach((nameControl) => {
                const control = {...state.formControls[nameControl]};
                const val = control.value;
                if (nameControl === "typeEntre") {
                    body[nameControl] =
                        (val === "fl" && 1) || (val === "ip" && 2) || (val === "ur" && 3);
                } else if (control.active) {
                    if (nameControl.startsWith("payer")) {
                        if (isExtraPayer) {
                            const name = getPayerFieldName(nameControl);
                            if (nameControl == "payerSubjectType" && val !== PAYER_TYPES.himself.value) {
                                body.payer = {};
                                const payerSubjectType = Object.values(PAYER_TYPES).find(type => type.value === val);
                                payerSubjectType && (body.payer[name] = payerSubjectType.id);
                            } else {
                                body.payer[name] = typeof val === "string" ? val.trim() : val;
                            }
                        }
                    } else if (nameControl.startsWith('region')) {
                        body[nameControl] = typeof val === "number" ? val : undefined;
                    } else if (nameControl.startsWith('separatedAddress')) {
                        const addressObjectName =
                            getters.getTypeEntreValue === 'ur'
                                ? 'legalAddressObject'
                                : 'actualAddressObject';
                        if (!['', null, undefined].includes(val)) {
                            if (!body.hasOwnProperty(addressObjectName)) {
                                body[addressObjectName] = {};
                            }
                            let actualControlName = nameControl
                                .replace('separatedAddress', '')
                                .replace('Type', '')
                                .replace('Value', '')
                            actualControlName = actualControlName.replace(actualControlName[0], actualControlName[0].toLowerCase());
                            const valueOrTypeMatch = nameControl.match(/Value|Type/);
                            let valueOrType;
                            if (valueOrTypeMatch) {
                                valueOrType = valueOrTypeMatch[0].replace(valueOrTypeMatch[0], valueOrTypeMatch[0].toLowerCase());
                            }
                            body[addressObjectName][actualControlName] =
                                !valueOrType
                                    ? typeof val === "string" ? val.trim() : val
                                    : {
                                        ...body[addressObjectName][actualControlName],
                                        [valueOrType]: typeof val === "string" ? val.trim() : val,
                                    };
                        }
                    } else {
                        body[nameControl] = typeof val === "string" ? val.trim() : val;
                    }
                }
            });

            try {
                commit(SET_IS_SUBMITED, true);

                //Установка соответсвия строки Snils для валидации на бэкэнде
                body.snils = body.snils.replace('-', '').replace(' ', '').replace('-', '');

                const res = await fetch.post(`api/InternalPreApplication/filial`, body);

                if (res.status === 200) {
                    const {files, result, orderId, managerId, statusId} = res.data;

                    if (!res.data.hasOwnProperty("error")) {
                        if (result) {
                            if (!getters.isIdentification) {
                                commit(SET_ORDER_ID, orderId);
                                commit(SET_STATUS_ID, statusId);
                            }
                            commit(SET_DISABLED, true);
                            commit(SET_IS_EDIT, false);

                            window.location.reload();
                        } else {
                            throw new Error("Что-то, пошло не так");
                        }
                    } else {
                        commit(SET_IS_SUBMITED, false);
                        let mess = `Ошибка при сохранении предзаявки ${state.orderId}: ${res.data.error}`;
                        Vue.$toast.error(mess, TOAST_OPTIONS.Error);
                    }
                }
            } catch (err) {
                const error = (err as any);
                commit(SET_IS_SUBMITED, false);
                const errorMessage = `Ошибка сохранения предзаявки ${state.orderId}: ${JSON.stringify(
                    error.response?.data || error.message || 'неизвестная ошибка',
                    null,
                    2
                )}`;
                logError(error, errorMessage);
                Vue.$toast.error(errorMessage, TOAST_OPTIONS.Error);
            }
        // } else {
        //     dispatch('setActiveTab', 1);
        //     scrollToInvalidFormItem();
        // }
    },

    async changeStatus({commit, dispatch, state}: any, value?: boolean) {
        commit(
            SET_CHANGE_STATUS_VISIBILITY,
            value !== undefined ? value : !state.isChangeStatusVisible
        )
    },

    async commitNewStatus({commit, dispatch, state}: any, newStatusId: number) {
        const body = {
            id: state.orderId,
            newStatusId: newStatusId,
            comment: state.managerComment && state.managerComment.date
                ? state.managerComment.text
                : state.managerComment
        };

        const { status: responseStatus, data, error } = await api.setStatus(body);

        if (responseStatus === 403) {
            router.push("/403");
        }

        if (error || !data) {
            Vue.$toast.error(
                `Не удалось изменить статус предзаявки: ${error ?? 'Нет данных'}`,
                TOAST_OPTIONS.Error,
            );
            logError(
                new Error(error ?? 'Нет данных'),
                `Ошибка изменения статуса предзаявки ${state.orderId}`,
            );
            return { success: false };
        }

        if (data?.orderId) {
            const status = Object.values(STATUSES).find(s => s.id === newStatusId)?.title;
            commit(SET_STATUS, status);
            commit(SET_STATUS_ID, newStatusId);
            Vue.$toast.success('Статус предзаявки изменён', TOAST_OPTIONS.Success);
            return { success: true };
        } else {
            const errorMessage = 'Не удалось изменить статус предзаявки: data.orderId = null';
            Vue.$toast.error(errorMessage, TOAST_OPTIONS.Error);
            logError(
                new Error(errorMessage),
                `Ошибка изменения статуса предзаявки ${state.orderId}`,
            );
            return { success: false };
        }
    },

    isGoodToCreateApp({commit, state}: any, statusId: any): boolean {
        if (parseInt(statusId) !== STATUSES.ApplicationFormed.id) {
            return true;
        } else {
            return false;
        }
    },
    isGoodToChangeStatus({commit, state}: any, statusId: any): boolean {
        if (parseInt(statusId) === STATUSES.NewPreApplication.id) {
            return true;
        } else {
            return false;
        }
    },

    checkValidityAndScroll({dispatch}){
        dispatch("checkValidity");
        scrollToInvalidFormItem();
    },

    /*
        отправляет запрос на подтверждение идентификации, в запросе отправляет способ уведомления: email или СМС
        при получении успешного ответа переводит предзаявку в статус "Идентификация подтверждена"
    */
    async approveIdentification({state, commit}, notificationType: string) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const body = {
                preapplicationId: Number(state.orderId),
                notificationType: Number(notificationType)
            };
            const res = await fetch.post(`/api/internalPreApplication/identification/approve`, body);
            if (res.status === 200 && res.data && res.data.success) {
                commit(SET_STATUS, IDENTIFICATION_STATUSES.Approved.title);
                commit(SET_STATUS_ID, IDENTIFICATION_STATUSES.Approved.id);
                const message = `Идентификация подтверждена. Предзаявка закрыта`
                Vue.$toast.success(message, TOAST_OPTIONS.Success);
            } else {
                const message = res.data && res.data.error ?
                    `Ошибка при подтверждении идентификации. ${res.data.error}` :
                    `Неизвестная ошибка при подтверждении идентификации`;
                throw new Error(message);
            }
        } catch (err) {
            const error = (err as any);
            const message = error.message || "Ошибка при подтверждении идентификации";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }  
    },

    /*
        отправляет запрос на подтверждение идентификации, в запросе отправляет комментарий с причиной отклонения
        при получении успешного ответа переводит предзаявку в статус "Идентификация отклонена"
    */
    async declainIdentification({state, commit}, comment: string) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const body = {
                preapplicationId: Number(state.orderId),
                comment
            };
            const res = await fetch.post(`/api/internalPreApplication/identification/decline`, body);
            if (res.status === 200 && res.data && res.data.success) {
                commit(SET_STATUS, IDENTIFICATION_STATUSES.Declained.title);
                commit(SET_STATUS_ID, IDENTIFICATION_STATUSES.Declained.id);
                const message = `Идентификация отклонена. Предзаявка закрыта`
                Vue.$toast.success(message, TOAST_OPTIONS.Success);
            } else {
                const message = res.data && res.data.error ?
                    `Ошибка при отклонении идентификации. ${res.data.error}` :
                    `Неизвестная ошибка при отклонении идентификации`;
                throw new Error(message);
            }
        } catch (err) {
            const error = (err as any);
            const message = error.message || "Ошибка при отклонении идентификации";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }  
    },

    /*
        скачивает файл на вкладке Документы (клиент) и открывает его в новом окне
    */
    async fetchScanAndOpen({}, item: any) {
        const {guid, header} = item;
        try {
          const res = await fetch.get(`api/InternalPreApplication/getScan/${guid}`);
          if (res.status === 200) {
            if (res.data.error == null) {
                if (header) {
                    const contentType = header.replace("data:", "").replace(";base64,", "").trim();
                    const blob = base64toBlob(`${header}, ${res.data.scan}`, contentType);
                    const fileJS = new File([blob], "file", {type: blob.type});
                    if(contentType.match(/(jpg|jpeg|png|gif|pdf|PDF|JPG|PNG)/)) {
                        const url = URL.createObjectURL(fileJS);
                        window.open(url, '_blank');
                    } else {
                        download(fileJS, decodeURI("file"), contentType);
                    }
                } else {
                    const url = "data:image/jpg;base64, " + res.data.scan;
                    openImgInNewTab(url);
                }
               
            } else {
              throw new Error(res.data.error);
            }
          }
        } catch (err) {
          const error = (err as any);
          logError(error, `Ошибка при загрузке скана предзаявки`);
        }
    },

    /*
        обрабатывает прикрепление файла на вкладке Документы (клиент)
    */
    async inputFileHandler({commit, state, dispatch}: any, evt: any) {
        const {name, files}  = evt.target;
        let upload: any;
        let tempUpload: any;

        if (name.startsWith("another")) {
            upload = {...state.anotherUploads[name]};
            tempUpload = {...state.anotherUploads[name]};
        } else {
            upload = {...state.uploads[name]};
            tempUpload = {...state.uploads[name]};
        }

        upload.isTouched = true;

        if (files[0]) {
            const errors: string[] = [];
            const namesFiles = [];
            const isMaxFiles = files.length > (upload.maxFiles ? upload.maxFiles : 1);

            const compiled = template(
                `<ul>
                    <% _.forEach( names, function(name) { %>
                        <li> <%- name %> </li>
                    <% }) %>
                </ul>`
            );

            if (!isMaxFiles) {
                const filesArr = isMaxFiles
                    ? upload.maxFiles
                    : files.length;

                upload.files = [];
                upload.errors = [];

                for (let i = 0; i < filesArr; i++) {
                    const file = files[i];

                    const errorsMessage = validationFile(file, {
                        rules: upload.rules,
                        errorMessage: upload.errorMessage,
                    });

                    if (errorsMessage.length) {
                        errorsMessage.forEach((error: any) => {
                            if (!~errors.indexOf(error)) {
                                errors.push(error);
                            }
                        });
                    }

                    namesFiles.push(file.name.replace(extname(file.name), ""));
                    upload.files.push(file);
                }

                upload.descr = compiled({names: namesFiles});
                upload.errors = [...errors];

                if (evt.target.name === 'foto') {
                    upload.captured = null;
                }
            } else {
                upload.files = [];
                upload.descr = "";
                upload.errors = [`Максимальное количество файлов ${upload.maxFiles}`];
            }
        }
        if (upload.files) {
            upload.isValid = upload.errors.length === 0;
            upload.isWatchable = true;
        } else {
            upload.isValid = false;
            upload.isWatchable = false;
        }
        try{
            if (name.startsWith("another")) {
                commit(SET_ANOTHER_UPLOAD, {name, upload});
                const file = upload.files[0];
                await dispatch("attachAnotherDocument", {name, file});
            } else {
                commit(SET_UPLOAD, {name, upload})
                const file = upload.files[0];
                await dispatch("attachDocument", {name, file});
            }
        } catch(err) {
            if (name.startsWith("another")) {
                commit(SET_ANOTHER_UPLOAD, {name, upload: tempUpload});
            } else {
                commit(SET_UPLOAD, {name, upload: tempUpload})
            }
        }
    },

    /*
        сохраняет документ в БД на сервере
    */
    async attachDocument({state, commit}, {name, file, fileName}: any) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const fileBase64 = Object.prototype.toString.call(file) == "[object File]"
                ? await fileToBase64(file)
                : file;
            const header = (fileBase64 as string).split("base64,")[0] + "base64";
            const preApplicationId = Number(state.orderId);
            const fileType = state.uploads[name as TUploadName]?.fileType;
            const fileNameWithoutExt = fileName || file.name.replace(extname(file.name), "");
            const body = {
                preApplicationId,
                scans: [{
                    fileType,
                    fileName: fileNameWithoutExt,
                    file: fileBase64
                }]
            };
            const res = await fetch.post(`/api/internalPreApplication/attachScan`, body);
            if (res.status === 200 && res.data && res.data.success) {
                const upload = state.uploads[name as TUploadName];
                const guid = res.data.result[0].fileGuid;
                commit(SET_UPLOAD, {name, upload: {...upload, guid, header}});
                const message = `Документ "${fileNameWithoutExt}" успешно загружен`
                Vue.$toast.success(message, TOAST_OPTIONS.Success);
            } else {
                const message = res.data && res.data.error ?
                    `Ошибка при загрузке документа "${fileNameWithoutExt}". ${res.data.error}` :
                    `Неизвестная ошибка при загрузке документа ${fileNameWithoutExt}`;
                throw new Error(message);
            }
        } catch (err) {
            const error = (err as any);
            const message = error.message || "Ошибка при загрузке документа";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
            throw err;
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }
    },

    async fotoCaptureHandler({commit, state, dispatch}, cameraimg) {
        const name = "foto";
        let fileName = "";
        const errors: string[] = [];
        const compiled = template(
            `<ul>
                    <li> <%- name %> </li>
            </ul>`
        );
        const upload: any = {...state.uploads[name]};
        upload.isTouched = true;
        upload.files = [];
        upload.errors = [];

        if (cameraimg) {
            fileName = `${state.orderId}_aplicant_photo_${new Date().getTime()}.jpg`;
            upload.captured = cameraimg;
            upload.descr = compiled({name: fileName.replace(extname(fileName), "")});
            upload.errors = [...errors];
            upload.isValid = upload.errors.length === 0;
            upload.isWatchable = true;
        } else {
            upload.isValid = false;
            upload.isWatchable = false;
        }

        commit(SET_UPLOAD, {name, upload});
        dispatch("attachDocument", {name, file: cameraimg, fileName});
    },

    /*
     подгружает информацию о "других документах" при переходе на вкладку "Документы (клиент)"
    */
     async fetchAnotherUploads({state, commit}) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const body = {applicationId: Number(state.orderId)};
            const res = await fetch.post(`/api/file/getOtherFilesPreApplication`, body);
            if (res.status === 200 && res.data && res.data.success) {
                const compiled = template(
                    `<ul>
                        <li> <%- fileName %> </li>
                    </ul>`
                );
                const {files} = res.data;
                files.forEach((item: any, index: number) => {
                    const {fileName, fileId} = item;
                    const name = Object.keys(state.anotherUploads)[index];
                    const upload: any = {
                        ...state.anotherUploads[name as TUploadName],
                        isValid: true,
                        isTouched: true,
                        isWatchable: true,
                        descr: compiled({fileName}),
                        fileId
                    }
                    commit(SET_ANOTHER_UPLOAD, {name, upload});
                })
            } else {
                const message = res.data && res.data.error ?
                    `Ошибка при загрузке других документов. " ${res.data.error}` :
                    "Неизвестная ошибка при загрузке других документов";
                throw new Error(message);
            }
        } catch(err) {
            const error = (err as any);
            const message = error.message || "Неизвестная ошибка при загрузке других документов";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }
    },

    /*
     сохраняет в заявке документ типа "Другой документ"
    */
     async attachAnotherDocument({state, commit}, {name, file}: any) {
        if (app) {
            (app as any).$modal.show("loader");
        }
        try {
            const fileBase64 = await fileToBase64(file);
            const initialApplicationId = Number(state.orderId);
            const fileName = file.name.replace(extname(file.name), "");
            const fileId = state.anotherUploads[name as TUploadName]!.fileId || null;
            const body = {
                initialApplicationId,
                file: fileBase64,
                fileName,
                fileId
            };
            const res = await fetch.post(`/api/file/uploadOtherFilePreApplication`, body);
            if (res.status === 200 && res.data && res.data.success) {
                const upload = {...state.anotherUploads[name as TUploadName], fileId: res.data.fileId};
                commit(SET_ANOTHER_UPLOAD, {upload, name});
                const message = `Документ "${fileName}" успешно загружен`
                Vue.$toast.success(message, TOAST_OPTIONS.Success);
            } else {
                const message = res.data && res.data.error ?
                    `Ошибка при загрузке документа "${fileName}". ${res.data.error}` :
                    `Неизвестная ошибка при загрузке документа ${fileName}`;
                throw new Error(message);
            }
        } catch (err) {           
            const error = (err as any);
            const message = error.message || "Ошибка при загрузке документа";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
            throw err;
        } finally {
            if (app) {
                (app as any).$modal.hide("loader");
            }
        }  
    },

    /*
     подгружает "другой документ" и открывает его в новой вкладке
    */
     async fetchAnotherDocument({state,}, name: string) {
        try {
            const body = {fileId: state.anotherUploads[name as TUploadName]!.fileId};
            const res = await fetch.post(`/api/file/getOtherFilePreApplication`, body);
            if (res.status === 200 && res.data && res.data.success) {
                const {file, name: fileName, header} = res.data;
                const contentType = header.replace("data:", "").replace(";base64,", "").trim();
                const blob = base64toBlob(`${header}, ${file}`, contentType);
                const fileJS = new File([blob], fileName, {type: blob.type});
                if(contentType.match(/(jpg|jpeg|png|gif|pdf|PDF|JPG|PNG)/)) {
                    const url = URL.createObjectURL(fileJS);
                    window.open(url, '_blank');
                } else {
                    download(fileJS, decodeURI(fileName), contentType);  
                }
            } else {
                const message = res.data && res.data.error ?
                    `Ошибка при загрузке документа "${state.anotherUploads[name as TUploadName]!.fileName}". " ${res.data.error}` :
                    `Неизвестная ошибка при загрузке документа "${state.anotherUploads[name as TUploadName]!.fileName}"`;
                throw new Error(message);
            }
        } catch(err) {
            const error = (err as any);
            const message = error.message || "Неизвестная ошибка при загрузке документа";
            Vue.$toast.error(message, TOAST_OPTIONS.Error);
            logError(error, message);
        }
    },

    /*
        Очищает все загрузки документов после ухода со страницы предзаявки
    */
    cleanAllUploads({commit}) {
        commit(CLEAN_UPLOADS);
        commit(CLEAN_ANOTHER_UPLOADS);
    },

    /*
     заменяет/добавляет свойство у выбранного control
    */
     setFormControlProps({state, commit}, {name, props}: {name: TControlName, props: any}) {
        const control = {...state.formControls[name], ...props};
        commit(SET_FORM_CONTROL, {control, name});
    }, 

    /*
     если в заявке выбраны продукты с ЭДО и код ФНС задан, отображать и проверять код ФНС
     (пока отказались от этого, код ФНС можно всегда задать в предзаявке)
    */
     defineFnsCodeActive({state, rootGetters, dispatch}) {
        if (rootGetters["productsState/getHasProductsWithEDM"]) {
            dispatch("setFormControlProps", {name: "fnsCode", props: {active: true, required: true}})
        }
    },

    async getPayerFieldsFromBasis({dispatch, getters}, {inn}: {inn: string}) {
        const initialApplicationType = getters.getPayerSubjectType === 'ur' ? 1 : 0;
        const {data, error} = await api.getFieldsFromBasis({initialApplicationType, inn});

        if (error || !data) {
            Vue.$toast.error(`Ошибка поиска плательщиков по ИНН: ${error || 'Нет данных'}`, TOAST_OPTIONS.Error);
            return;
        }

        if (data.status !== BASIS_STAUSES.SUCCESS) {
            Vue.$toast.error(`По данном ИНН получен неоднозначный ответ status: ${data.status}`, TOAST_OPTIONS.Error);
            return;
        }

        const payerFields: IPayerFields =
            getters.getPayerSubjectType === 'ur' ? 
                {
                    payerName: data.fullNameOrg,
                    // payerShortName: data.shortNameOrg,
                    payerInn: data.inn,
                    payerKpp: data.kpp,
                    payerPhone: data.phoneOrg,
                    payerCountryId: DEFAULT_COUNTRY.value,
                    payerRegionId: data.regionLawId,
                    payerCity: data.cityLaw,
                    payerAddress: data.addressLaw || data.addressFNS,
                    payerPostalCode: data.index,
                } : getters.getPayerSubjectType === 'ip' ?
                    {
                        payerName: data.fullNameOrg,
                        payerInn: data.inn,
                        payerPhone: data.phoneOrg,
                        payerCountryId: DEFAULT_COUNTRY.value,
                        payerRegionId: data.regionRealId,
                        // payerCity: data.cityReal, // поле cityReal не приходит от базиса
                        payerAddress: data.addressFNS, // поле addressReal не приходит от базиса
                    } : {};

        if (Object.keys(payerFields).length) {
            dispatch('autofillPayerFields', payerFields); 
        }   
    },

    autofillPayerFields({state, commit}, payerFields: IPayerFields) {
        type payerFieldName = Extract<keyof typeof state.formControls, keyof IPayerFields>;

        Object.entries(payerFields).forEach(([name, value]) => {
            if (
                state.formControls.hasOwnProperty(name) &&
                state.formControls[name as payerFieldName].active
            ) {
                const control = {...state.formControls[name as payerFieldName]};
                control.value = value;
                if (control.rules) {
                    const { isValid } = validation(control.value, control.rules);
                    control.isValid = isValid;
                }
                control.isTouched = true;
                
                commit(SET_FORM_CONTROL, {control, name});
            }
        });
    },

    async readyForUpd({state, commit}) {
        if (!state.orderId) {
            Vue.$toast.error(`Ошибка запроса формирования лицензии: orderId is null`, TOAST_OPTIONS.Error);
            return;
        }

        const {error} = await api.readyForUpd(state.orderId);

        if (error) {
            Vue.$toast.error(`Ошибка запроса формирования лицензии: ${error}`, TOAST_OPTIONS.Error);
            return;
        }

        Vue.$toast.success(`Запрос на формирование лицензии отправлен`, TOAST_OPTIONS.Success);
        commit(SET_UPD_STATUS, UpdRequestStatuses.RequestSended);
    },

    defineAddressFields({state, commit, dispatch}: any, hasTariffInsurance: boolean) {
        commit(DEFINE_ADDRESS_FIELDS, {subjectType: state.formControls.typeEntre.value, hasTariffInsurance});
        // dispatch("checkValidity");
    },

    defineLicenseFields({ commit }) {
      commit(DEFINE_LICENSE_FIELDS);
    },
    
    setNotRequiredLicenseFields({commit, state}) {
        commit(NOT_REQUIRED_LICENSE_FIELDS, {subjectType: state.formControls.typeEntre.value});
    },

    defineTokenFields({ commit }) {
      commit(DEFINE_TOKEN_FIELDS);
    },

    setNotRequiredTokenFields({commit, state}) {
        commit(NOT_REQUIRED_TOKEN_FIELDS, {subjectType: state.formControls.typeEntre.value});
    },

    /*
     Запрос имени подразделения по коду подразделения
    */
    async getDivisionNameByCode({commit, state}: any) {
        const divisionCode = state.formControls.passportDivisionCode.value;

        const {data, error} = await api.getDivisionInfo(divisionCode);

        if (error || !data) {
            Vue.$toast.error('Произошла ошибка, введите поле "Кем выдан" вручную', TOAST_OPTIONS.Error);
            return;
        }

        if (data && data.suggestions.length === 0) {
            Vue.$toast.error('Подразделение с таким кодом не найдено, введите поле "Кем выдан" вручную', TOAST_OPTIONS.Error);
            return;
        }

        if (data && data.suggestions.length > 1) {
            const optionsPassportDivisions = data.suggestions.map(
              (division) => ({ label: division.value, value: division.value })
            );

            commit(SET_OPTIONS_PASSPORT_DIVISIONS, optionsPassportDivisions);
        }

        const passportDivision = data.suggestions[0].value;
        commit(SET_PASSPORT_DIVISION, passportDivision);
    },

    /*
     Переключение списка подразделений на текстовый ввод
    */
    switchDivisionSelectToTextArea({commit}) {
        commit(CLEAR_OPTIONS_PASSPORT_DIVISIONS);
    },
};
