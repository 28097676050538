






















import Vue, { PropType } from "vue";

import CalendarCell from '@/components/CalendarCell.vue';
import { ICalendarCell } from "@/store/modules/calendar/calendar.types";

export default Vue.extend({
  name: "CalendarRow",

  components: {
    CalendarCell,
  },

  props: {
    items: {
      type: Array,
      required: true
    }, 
    isEditing: Boolean,
    today: String,
    isTHead: Boolean,
    isNotAllowedToChange: Function as PropType<(cell: ICalendarCell) => boolean>,
  },

  methods: {
    cellClassNames(cell: ICalendarCell) {
      const cn = [];
      if (cell.isOutOfWork) cn.push('is-out-of-work');
      if (cell.date === this.today) cn.push('today');
      if (this.isEditing) cn.push('edit');
      if (this.isNotAllowedToChange(cell)) cn.push('not-allowed-to-appointment');
      if (cell.appointments?.length) cn.push('allowed-to-show');
      return cn.join(' ');
    },
  },
})
