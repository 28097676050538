import { IFilterControls } from "../filter.interfaces";
import { ALL_FILTER_CONTROLS } from "./filter.defaults";

const defaultPreappFilterControls: IFilterControls = {
  applicant: {
    title: "Заявитель",
    name: "applicant",
    controls: {
      fl: {...ALL_FILTER_CONTROLS.fl},
      ip: {...ALL_FILTER_CONTROLS.ip},
      ur: {...ALL_FILTER_CONTROLS.ur},
    },
  },
  status: {
    title: "Статус",
    name: "status",
    controls: {
      statusNew: {...ALL_FILTER_CONTROLS.statusNew},
      statusAppFormed: {...ALL_FILTER_CONTROLS.statusAppFormed},
      statusInWork: {...ALL_FILTER_CONTROLS.statusInWork},
      statusWaitForConfirm: {...ALL_FILTER_CONTROLS.statusWaitForConfirm},
      statusWaitForDocs: {...ALL_FILTER_CONTROLS.statusWaitForDocs},
      statusClientRefused: {...ALL_FILTER_CONTROLS.statusClientRefused},
      statusOutOfReach: {...ALL_FILTER_CONTROLS.statusOutOfReach},
      statusOtherReason: {...ALL_FILTER_CONTROLS.statusOtherReason},
      statusDuplicate: {...ALL_FILTER_CONTROLS.statusDuplicate},
      statusTokenSent: {...ALL_FILTER_CONTROLS.statusTokenSent},
    }
  },
  identification: {
    title: "Идентификация",
    name: "identification",
    controls: {
      statusIdentificationCreated: {...ALL_FILTER_CONTROLS.statusIdentificationCreated},
      statusIdentificationConfirmed: {...ALL_FILTER_CONTROLS.statusIdentificationConfirmed},
      statusIdentificationCanceled: {...ALL_FILTER_CONTROLS.statusIdentificationCanceled},
    }
  },
  dateCreate: {
    title: "Период создания",
    name: "dateCreate",
    controls: {
      dateCreateFrom: {...ALL_FILTER_CONTROLS.date},
      dateCreateTo: {...ALL_FILTER_CONTROLS.date}
    }
  },
  dateIssue: {
    title: "Период выдачи",
    name: "dateIssue",
    controls: {
      dateIssueFrom: {...ALL_FILTER_CONTROLS.date},
      dateIssueTo: {...ALL_FILTER_CONTROLS.date}
    }
  },
  paymentStatus: {
    title: "Статус оплаты",
    name: "paymentStatus",
    controls: {
      paymentStatus: {
        type: "select",
        name: "paymentStatus",
        value: -1,
        options: [],
        disabled: false,
        touched: true
      }
    }
  },
  productId: {
    title: "Продукт",
    name: "productId",
    controls: {
      productId: {
        type: "select",
        name: "productId",
        value: -1,
        options: [],
        disabled: false,
        touched: true,
        hasSearch: true,
      }
    }
  },
};

export default defaultPreappFilterControls;
