import { TCalendarDayName } from './calendar.types';

export const CALENDAR_DAY_NAMES: TCalendarDayName[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const RUS_DAY_NAMES = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

export const CALENDAR_CELL_HEIGHT = 54; // 54px

export const CALENDAR_LAST_NOT_WORKING_TIMEVALUE = 30; // последние 30 минут филиалы не принимают записи

export enum CALENDAR_APPLICATION_TYPES {
  Application = 1,
  Preapplication = 2,
}
