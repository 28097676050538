
















































































































































































































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';

import {
  Header,
  Footer,
} from '@/components';
import Button from '@/components/UI/Button.vue';
import PageLayout from '@/components/layouts/PageLayout.vue';
import {
  Table,
  Thead,
  TableRow,
  CalendarRow,
  CalendarBody,
} from "@/components/UI";
import WeekPicker from '@/components/WeekPicker.vue';

import { PATHS } from '@/router';
import ROLES from "@/config/roles";
import { PAGE_SHORT_NAMES } from '@/config/pages';
import { MIN_CONTAINER_WIDTH } from "@/constants";
import TABLE_COLUMNS from '@/config/table-columns';

import { getDatesByWeekDate, DDMMyyyyToTimestamp, getDateNames } from '@/helpers/date';
import { CALENDAR_APPLICATION_TYPES, RUS_DAY_NAMES } from '@/store/modules/calendar/calendar.config';
import { ICalendarDataRow, ICalendarAppointment, ICalendarCell } from '@/store/modules/calendar/calendar.types';

export interface IAppointment {
  date: string;
  time: string;
  name: string;
  phone: string;
  email: string;
  comment: string;
}

export interface ICalendarData {
  dates: Date[];
  isEditing: boolean;
  appointmentModalData: null | {
    date: string;
    time: string;
    appointments: ICalendarAppointment[] | null;
    isAvailable: boolean;
  };
  confirmModalData: null | { date: string; time: string },
  deleteConfirmModalOpen: boolean;
  editingAppointment: null | (IAppointment & { orderId: number; applicationType: CALENDAR_APPLICATION_TYPES });
  today: string;
  CALENDAR_APPLICATION_TYPES: typeof CALENDAR_APPLICATION_TYPES;
}

interface ICalendarMethods {
  calendarInit: ({ dates } : { dates: Date[] }) => Promise<void>;
  calendarChangeAppointment: ({ orderId, applicationType, officeId, prevAppointment, newAppointment }: { orderId: number; applicationType: CALENDAR_APPLICATION_TYPES; officeId: number; prevAppointment: IAppointment; newAppointment: IAppointment }) => Promise<boolean>;
  calendarAddAppointment: ({ orderId, applicationType, officeId, newAppointment }: { orderId: number; applicationType: CALENDAR_APPLICATION_TYPES; officeId: number; newAppointment: IAppointment }) => Promise<boolean>;
  calendarDeleteAppointment: ({ orderId, applicationType, date, time }: { orderId: number; applicationType: CALENDAR_APPLICATION_TYPES; date: string; time: string }) => Promise<boolean>;
  calendarClearState: () => void;
  cancelEditing: () => void;
  changeAppointmentSubmit: ({ orderId, applicationType, officeId, prevAppointment, newAppointment }: { orderId: number; applicationType: CALENDAR_APPLICATION_TYPES; officeId: number; prevAppointment: IAppointment; newAppointment: IAppointment }) => void;
  addAppointmentSubmit: ({ orderId, applicationType, officeId, newAppointment }: { orderId: number; applicationType: CALENDAR_APPLICATION_TYPES; officeId: number; newAppointment: IAppointment }) => void;
  deleteAppointmentSubmit: ({ orderId, applicationType, date, time }: { orderId: number; applicationType: CALENDAR_APPLICATION_TYPES; date: string; time: string }) => void;
  setTableContentType: (name: PAGE_SHORT_NAMES) => void;
  goTo: (url: string) => void;
  isNotAllowedToChange: (cell: ICalendarCell) => boolean;
  cellClickHandler: (cell: ICalendarCell) => void | null;
}

interface ICalendarComputed {
  calendarDataWithAppointments: ICalendarDataRow[];
  getDefaultContainerWidth: string;
  getColumnTitles: string[];
  getDefaultCellsWidth: string[];
  getRole: ROLES;
  calendarNewAppointment: IAppointment;
  calendarCurrentAppointment: ICalendarData['editingAppointment'];
}

export default Vue.extend<ICalendarData, ICalendarMethods, ICalendarComputed>({
  name: 'Calendar',

  components: {
    Header,
    Footer,
    PageLayout,
    Table,
    Thead,
    TableRow,
    CalendarRow,
    CalendarBody,
    WeekPicker,
    Button,
  },

  data() {
    return {
      dates: getDatesByWeekDate(new Date()),
      isEditing: false,
      appointmentModalData: null as (ICalendarData['appointmentModalData'] | null),
      confirmModalData: null as (ICalendarData['confirmModalData'] | null),
      deleteConfirmModalOpen: false,
      editingAppointment: null as (ICalendarData['editingAppointment'] | null),
      today: moment(new Date()).format('DD.MM.yyyy') as ICalendarData['today'],
      CALENDAR_APPLICATION_TYPES,
      PATHS,
    };
  },

  computed:{
    ...mapState('calendarState', [
      'calendarNewAppointment',
      'calendarCurrentAppointment'
    ]),
    ...mapGetters('calendarState', [
      'calendarDataWithAppointments',
    ]),
    ...mapGetters("authorizationState", ["getRole"]),

    getDefaultContainerWidth() {
      return (MIN_CONTAINER_WIDTH + "px");
    },

    getColumnTitles() {
      return [
        'время',
        `${RUS_DAY_NAMES[0]} ${getDateNames(this.dates)[0]}`,
        `${RUS_DAY_NAMES[1]} ${getDateNames(this.dates)[1]}`,
        `${RUS_DAY_NAMES[2]} ${getDateNames(this.dates)[2]}`,
        `${RUS_DAY_NAMES[3]} ${getDateNames(this.dates)[3]}`,
        `${RUS_DAY_NAMES[4]} ${getDateNames(this.dates)[4]}`,
        `${RUS_DAY_NAMES[5]} ${getDateNames(this.dates)[5]}`,
        `${RUS_DAY_NAMES[6]} ${getDateNames(this.dates)[6]}`,
      ];
    },

    getDefaultCellsWidth() {
      const columns = TABLE_COLUMNS[PAGE_SHORT_NAMES.calendar][this.getRole] ?? TABLE_COLUMNS[PAGE_SHORT_NAMES.calendar][ROLES.Manager];

      return columns!.map(({ part }) => part * 100 + "%");
    }
  },

  methods: {
    ...mapActions('calendarState', [
      'calendarInit',
      'calendarAddAppointment',
      'calendarChangeAppointment',
      'calendarDeleteAppointment',
      'calendarClearState',
    ]),
    ...mapActions("tableState", ["setTableContentType"]),

    async changeAppointmentSubmit({
      orderId,
      applicationType,
      officeId,
      prevAppointment,
      newAppointment
    }) {
      const success = await this.calendarChangeAppointment({
        orderId,
        applicationType,
        officeId,
        prevAppointment,
        newAppointment,
      });
      if (success) {
        this.editingAppointment = null;
        this.$modal.hide('calendar-confirm-modal');
        this.isEditing = false;
        this.calendarClearState();
        await this.calendarInit({ dates: this.dates });
      }
    },

    async addAppointmentSubmit({
      orderId,
      applicationType,
      officeId,
      newAppointment,
    }) {
      const success = await this.calendarAddAppointment({
        orderId,
        applicationType,
        officeId,
        newAppointment,
      });
      if (success) {
        this.$store.commit('calendarState/SET_CALENDAR_NEW_APPOINTMENT', null);
        this.$store.commit('calendarState/SET_CALENDAR_CURRENT_APPOINTMENT', null);
        this.$modal.hide('calendar-confirm-modal');
        this.isEditing = false;
        this.calendarClearState();
        await this.calendarInit({ dates: this.dates });
      }
    },

    async deleteAppointmentSubmit({
      orderId,
      applicationType,
      date,
      time,
    }) {
      const success = await this.calendarDeleteAppointment({
        orderId,
        applicationType,
        date,
        time,
      });
      if (success) {
        this.editingAppointment = null;
        this.deleteConfirmModalOpen = false;
      }
    },

    cancelEditing() {
      this.editingAppointment = null;
      this.$store.commit('calendarState/SET_CALENDAR_NEW_APPOINTMENT', null);
      this.$store.commit('calendarState/SET_CALENDAR_CURRENT_APPOINTMENT', null);
      this.isEditing = false;
    },

    goTo(url: string) {
      this.$router.push(url);
    },

    isNotAllowedToChange(cell: ICalendarCell) {
      return !!(
        cell.isOutOfWork ||
        !cell.isAvailable ||
        (this.editingAppointment?.date === cell.date &&
          this.editingAppointment?.time === cell.timeCode) ||
        (DDMMyyyyToTimestamp(cell.date, '.') <
          DDMMyyyyToTimestamp(moment(new Date()).format('DD.MM.yyyy'), '.'))
      );
    },

    cellClickHandler(cell: ICalendarCell) {
      if (this.isEditing && this.isNotAllowedToChange(cell)) return null;
      if (!this.isEditing && !cell.appointments?.length) return null;
      if (!this.isEditing && !!cell.appointments?.length) {
        this.appointmentModalData = { 
          date: cell.date,
          time: cell.timeCode,
          appointments: cell.appointments,
          isAvailable: cell.isAvailable,
        };
        this.$modal.show('calendar-show-appointments-modal');
        return;
      }
      if (this.isEditing && !!this.calendarNewAppointment) {
        this.confirmModalData = {
          date: cell.date,
          time: cell.timeCode,
        };
        this.$modal.show('calendar-confirm-modal');
        return;
      }
      if (this.isEditing && !!this.editingAppointment) {
        this.confirmModalData = {
          date: cell.date,
          time: cell.timeCode,
        };
        this.$modal.show('calendar-confirm-modal');
        return;
      }
    },
  },

  watch: {
    dates() {
      this.calendarInit({ dates: this.dates });
    },
  },

  beforeMount() {
    this.setTableContentType(PAGE_SHORT_NAMES.calendar);
  },

  mounted() {
    this.calendarInit({ dates: this.dates });
    if (!!this.calendarNewAppointment) {
      this.isEditing = true;
    }
    if (!!this.calendarCurrentAppointment) {
      this.isEditing = true;
      this.editingAppointment = this.calendarCurrentAppointment;
    }
  },

  beforeDestroy() {
    this.calendarClearState();
  },
});
