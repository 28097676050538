









































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mapState, mapGetters } from "vuex";
import { isEven } from "@/utils";

import IconBase from "@/components/UI/IconBase.vue";
import Loading from "@/components/UI/icon/Loading.vue";
import Doc from "@/components/UI/icon/Doc.vue";
import DocFoto from "@/components/UI/icon/DocFoto.vue";
import DocPassport from "@/components/UI/icon/DocPassport.vue";
import DocSnils from "@/components/UI/icon/DocSnils.vue";
import DocScore from "@/components/UI/icon/DocScore.vue";
import DoсCert from "@/components/UI/icon/DoсCert.vue";
import DocKey from "@/components/UI/icon/DocKey.vue";
import Checked from "@/components/UI/icon/Checked.vue";
import Close from "@/components/UI/icon/Close.vue";
import Button from "@/components/UI/Button.vue";
import Select from "@/components/UI/form/Select.vue";
import { BASE_URL_AUTH, BASE_URL_API } from "@/constants";

//import Cam from "@/components/WebCam.vue";
import { WebCam } from "vue-web-cam";

interface IValidationProps {
  valid: boolean;
  touched: boolean;
  shouldValidate: boolean;
}

@Component({
  computed: {
    ...mapGetters("authorizationState", ["isManagerPartner"])
  },
  components: {
    IconBase,
    Loading,
    Doc,
    DocFoto,
    DocPassport,
    DocSnils,
    DocScore,
    DoсCert,
    DocKey,
    Checked,
    Close,
    Button,
    Select,
    "vue-web-cam": WebCam
  }
})
export default class ItemDocument extends Vue {
  @Prop({ type: String }) public img!: string;
  @Prop({ type: String, default: "doc" }) public type!: string;
  @Prop({ type: String, default: "" }) public accept!: string;
  @Prop({ type: String, default: "" }) public name!: string;
  @Prop({ type: String }) public title!: string;
  @Prop({ type: Number, default: 1 }) public maxFiles!: number;
  @Prop({ type: String }) public descr!: string;
  @Prop({ type: Array }) public errorsList!: [];
  @Prop({ type: Number }) public index!: number;
  @Prop({ type: Boolean }) public touched!: boolean;
  @Prop({ type: Boolean }) public shouldValidate!: boolean;
  @Prop({ type: Boolean, default: false }) public isUploadable!: boolean;
  @Prop({ type: Boolean, default: false }) public isWatchable!: boolean;
  @Prop({ type: Boolean, default: false }) public isDownloadable!: boolean;
  @Prop({ type: Boolean, default: false }) public valid!: boolean;
  @Prop({ type: Boolean, default: false }) public disabled!: boolean;
  @Prop({type: Boolean, default: false}) public fromCamera!: boolean;
  @Prop({type: Boolean, default: false}) public isExtra!: boolean;
  @Prop({type: Boolean, default: false}) public isFilled?: boolean;
  @Prop({ type: Number}) public fileId!: number;

  private cameraimg: any = null;
  private camera: any =  null;
  private deviceId: any = null;
  private devices: Array<any> = [];

  get device(): any {
    return this.devices.find(n => n.deviceId === this.deviceId);
  }

  @Watch('camera')
  cameraChanged(id: any){
    this.deviceId=id;
  }
  @Watch('devices')
  devicesChanged(){
    const [first, ...tail] = this.devices;
    if (first) {
      this.camera = first.deviceId;
      this.deviceId = first.deviceId;
    } else {
      this.deviceId=null;
      this.camera=null;
      this.cameraimg=null;
    }
  }

  private onCapture():void {
    // @ts-ignore
    this.cameraimg = this.$refs.webcam.capture();
  }
 // private onStarted(stream):void {
 //  console.log("On Started Event", stream);
 // }
 // private onStopped(stream):void {
 //   console.log("On Stopped Event", stream);
 // }
  private onStop():void {
    // @ts-ignore
    this.$refs.webcam.stop();
  }
  private onStart():void {
    // @ts-ignore
    this.$refs.webcam.start();
  }
  // @ts-ignore
  private onError(error):void {
    this.camera=null;
    this.deviceId=null;
  }
  // @ts-ignore
  private onCameras(cameras):void {
    this.devices = cameras;
    console.log("On Cameras Event", cameras);
  }
  // @ts-ignore
  private onCameraChange(deviceId):void {
    this.deviceId = deviceId;
    this.camera = deviceId;
    console.log("On Camera Change Event", deviceId);
  }
  

  private loaded: boolean = false;
  public isValidation = ({
    valid,
    touched,
    shouldValidate
  }: IValidationProps) => {
    return !valid && touched && shouldValidate;
  };

  get getMaxFiles() {
    return this.maxFiles < 1 ? 1 : this.maxFiles;
  }

  get position() {
    return isEven(this.index) ? "left" : "right";
  }

  private onLoaded() {
    this.loaded = true;
  }

  private renderCam(){
    this.$modal.show("webcam_modal");
  }

  private onSaveFoto(){
    console.log("start saving foto");
    
    this.$emit('foto-capture',this.cameraimg);
    this.camera=null;
    this.deviceId=null;
    setTimeout(this.nullImg,500);
    this.$modal.hide("webcam_modal");
  }

  private onCancel(){
    this.nullImg();
    this.camera=null;
    this.deviceId=null;
    this.$modal.hide("webcam_modal");  
  }
  private nullImg(){
    this.cameraimg=null;
  }
}
