import { GetterTree } from "vuex";

import moment from "moment";
import { isEmpty } from "lodash";

import { RootState } from "@/store/root.interface";
import {
  TYPE_ENTRES,
  PREAPP_TYPES,
  IDENTIFICATION_STATUSES,
  ANOTHER_UPLOADS_MAX_COUNT
} from "./preapplication.constants";
import {
  DEFAULT_ANOTHER_UPLOAD,
} from "./preapplication.defaultUploads";
import { IPreApplicationState, TTypeEntreName, TUploadName, TControlName } from "./preapplication.interfaces";
import { UpdRequestStatuses, UpdStatusTitles } from "@/constants/UpdRequestStatuses";
import { InitialFileTypes } from "@/constants/InitialFileTypes";
import { ORGANIZATION_TYPE_IDS } from '@/constants/organization';

const getTypeEntreByKey = (typeEntreName: TTypeEntreName, key: "id" | "title") => {
  const typeEntreObj = TYPE_ENTRES.find((t: any) => t.name === typeEntreName);
  return !isEmpty(typeEntreName) && !!typeEntreObj ? typeEntreObj[key] : "";
};

export const getters: GetterTree<IPreApplicationState, RootState> = {
  getVisibleBodyForm(state) {
    return !isEmpty(state.formControls.typeEntre.value);
  },

  getPreApplicationInfo(state: any) {
    return {
      status: state.status,
      orderId: state.orderId,
      creationDate: moment(state.creationDate)
        .locale("ru")
        .format("LLL"),
      typeEntre: getTypeEntreByKey(state.formControls.typeEntre.value, "title"),
      statusId: state.statusId,
      filialComment: state.managerComment,
    };
  },
  getAdditionalStatusVisible(state:any){
    return state.isChangeStatusVisible;
  },

  getClientDocuments(state, getters) {
    let documents: any[] = [];
    let anotherUploadsCount: number = 0;
    const isIdentification = getters.isIdentification;
    const uploads: any = {...state.uploads};

    if (isIdentification) {
      Object.assign(uploads, state.anotherUploads);
    }

    Object.keys(uploads).forEach((name: any) => {
      const upload = {...uploads[name as TUploadName]};
      if (!name.startsWith("another") && isIdentification && upload.isIdentificationContent) {
        documents.push(upload);
      } else if (!name.startsWith("another") && !isIdentification && upload.isPreappContent && !!upload.guid) {
        if (upload.fileType === InitialFileTypes.UPD) {
          upload.header = 'application/pdf';
        }
        documents.push(upload);
      } else if (name.startsWith("another") && upload.isTouched) {
        documents.push(upload);
        anotherUploadsCount++;
      }
    });

    if (
      isIdentification
      && !documents.find((item: any) => item.name.startsWith("another") && !item.isTouched)
      && anotherUploadsCount < ANOTHER_UPLOADS_MAX_COUNT
    ) {
      documents.push({...DEFAULT_ANOTHER_UPLOAD, name: `another${anotherUploadsCount}`});
    }

    return documents;
  },

  isIdentification(state): boolean {
    return state.typeId === PREAPP_TYPES.Identification.id;
  },

  isIdentificationClosed(state): boolean {
    return state.statusId === IDENTIFICATION_STATUSES.Approved.id || state.statusId === IDENTIFICATION_STATUSES.Declained.id;
  },

  getFnsExistingCerts(state) {
    const certs = state.fnsExistingCerts.map(((cert: any) => ({
        ...cert, issued: cert.issued.replace(/\s\d\d:\d\d:\d\d/, "")
    })))
    return certs;
  },

  getTypeEntreValue(state) {
    return state.formControls.typeEntre.value;
  },

  correctManagerComment(state) {
    let comment = state.managerComment;

    if (comment && typeof comment !== 'string') {
      comment = comment.text;
    }
    
    return comment;
  },

  getPayerSubjectType(state) {
    return state.formControls.payerSubjectType.value;
  },

  getIsFormValid(state) {
    let isFormValid = true;
    Object.keys(state.formControls).forEach(controlName => {
        const control: any = state.formControls[controlName as TControlName];
        if (control.hasOwnProperty("isValid") && control.active) {
            isFormValid = control.isValid && isFormValid;
        }
    });
    return isFormValid;
  },

  hasTariffLicense(state) {
    return state.skziPreapp;
  },

  disabledClickReadyForUpd(state) {
    return (
      state.preappPaymentStatus === true &&
      state.updStatus !== UpdRequestStatuses.NotSended &&
      state.updStatus !== null
    );
  },

  disabledChangePreappWithLicense(state) {
    // во всех статусах формирования лицензии, кроме "не сформирован" и "отказ"
    return ([
      UpdRequestStatuses.RequestCreated,
      UpdRequestStatuses.RequestSended,
      UpdRequestStatuses.FormedIn1C,
      UpdRequestStatuses.UPDSuccess,
    ] as Array<UpdRequestStatuses | null>).includes(state.updStatus);
  },

  updStatusTitle(state) {
    if (state.preappPaymentStatus !== true) return 'не оплачено';
    if (state.updStatus === null || !(state.updStatus in UpdRequestStatuses)) return 'не известно';
    return UpdStatusTitles[state.updStatus];
  },

  getDisplaySmsSection(state, getters, rootState, rootGetters) {
    return rootState.filialInfo?.organizationTypeId === ORGANIZATION_TYPE_IDS.Agent;
  },
};
