import moment from 'moment';

export const getWeekCountByDate = (date?: Date) => {
  // source is https://weeknumber.net/how-to/javascript
  // основано на том, что 4 января всегда на первой неделе
  const _date = date ? new Date(date) : new Date();
  _date.setHours(0, 0, 0, 0);
  _date.setDate(_date.getDate() + 3 - ((_date.getDay() + 6) % 7));
  const firstWeek = new Date(_date.getFullYear(), 0, 4);
  return (
    1 +
    Math.round(
      ((_date.getTime() - firstWeek.getTime()) / 86400000 -
        3 +
        ((firstWeek.getDay() + 6) % 7)) /
        7,
    )
  );
};

export const getDatesByWeekCount = (weekCount: number) => {
  // основано на том, что 4 января всегда на первой неделе
  const year = new Date().getFullYear();
  const fourthJanuaryDate = new Date(`${year}-01-04`);
  const fourthJanuaryDay = new Date(`${year}-01-04`).getDay();
  const mondayOnWeek =
    fourthJanuaryDate.getTime() +
    ((weekCount - 1) * 7 - (fourthJanuaryDay - 1)) * 24 * 3600 * 1000;
  const dates = new Array(7)
    .fill('')
    .map((_, index) => new Date(mondayOnWeek + index * 24 * 3600 * 1000));

  return dates;
};

export const getDatesByWeekDate = (date: Date) => {
  // основано на том, что 4 января всегда на первой неделе
  const _date = date;
  _date.setHours(0, 0, 0, 0);
  _date.setDate(_date.getDate() + 3 - ((_date.getDay() + 6) % 7));
  const firstWeek = new Date(_date.getFullYear(), 0, 4);
  const year = _date.getFullYear();
  const weekCount = (
    1 +
    Math.round(
      ((_date.getTime() - firstWeek.getTime()) / 86400000 -
        3 +
        ((firstWeek.getDay() + 6) % 7)) /
        7,
    )
  );
  const fourthJanuaryDate = new Date(`${year}-01-04`);
  const fourthJanuaryDay = new Date(`${year}-01-04`).getDay();
  const mondayOnWeek =
    fourthJanuaryDate.getTime() +
    ((weekCount - 1) * 7 - (fourthJanuaryDay - 1)) * 24 * 3600 * 1000;
  const dates = new Array(7)
    .fill('')
    .map((_, index) => new Date(mondayOnWeek + index * 24 * 3600 * 1000));

  return dates;
};

export const getDateNames = (dates: Date[]) => {
  return dates.map((date) => moment(date).format('DD.MM.yyyy'));
};

export const timestampToHHmm = (timestamp: number) => {
  return moment(
    new Date(
      timestamp * 60 * 1000 + new Date(0).getTimezoneOffset() * 60 * 1000,
    ),
  ).format('HH:mm');
};

export const hhmmToTimestamp = (hhmm: string) => {
  return Number(hhmm.split(':')[0]) * 60 + Number(hhmm.split(':')[1]);
};

export const DDMMyyyyToTimestamp = (DDMMyyyy: string, separator: string) => {
  const arr = DDMMyyyy.split(separator).map((d) => Number(d));
  return new Date(arr[2], arr[1] - 1, arr[0]).getTime();
};

/*
 пн - 0б вт - 1, ср - 2, чт - 3, пт - 4, сб - 5, вс - 6
*/
export const DDMMyyyyToDayIndex = (DDMMyyyy: string, separator: string) => {
  const date = new Date(DDMMyyyyToTimestamp(DDMMyyyy, separator));
  const index = (date.getDay() + 6) % 7; 
  return index;
};
