import { IFormControls } from "./preapplication.interfaces";

export const DEFAULT_FORM_CONTROLS: IFormControls = {
  typeEntre: {
    value: "",
    active: true,
    disabled: false
  },
  surname: {
    value: "",
    active: true,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  name: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  patronymic: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  gender: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      empty: true,
      gender: true,
    },
  },
  birthDate: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      date: true
    },
    invalidityInfo: "",
  },
  dateDoc: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      date: true
    },
    invalidityInfo: "",
  },
  serialDoc: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      minLength: 10
    },
    invalidityInfo: "",
  },
  passportDivision: {
    value: "",
    active: false,
    disabled: false,
    isTouched: true,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  passportDivisionCode: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      minLength: 6
    },
    invalidityInfo: "",
  },
  inn: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      inn: true,
      minLength: 10
    },
    invalidityInfo: "",
  },
  personInn: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      inn: true,
      minLength: 10
    },
    invalidityInfo: "",
  },
  snils: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      snils: true,
      minLength: 11
    },
    invalidityInfo: "",
  },
  ogrnip: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      ogrnip: true,
      minLength: 15
    },
    invalidityInfo: "",
  },
  position: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  department: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  ogrn: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      ogrn: true,
      minLength: 13
    },
    invalidityInfo: "",
  },
  countryId: {
    value: 193,
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  regionRealId: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false,
    invalidityInfo: "",
  },
  cityReal: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  addressReal: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      cyrillic: true,
    },
    invalidityInfo: "",
  },
  regionLawId: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  cityLaw: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  addressLaw: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    rules: {
      cyrillic: true,
    },
    invalidityInfo: "",
  },
  email: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      email: false
    },
    invalidityInfo: "",
  },
  phone: {
    value: "",
    active: false,
    isTouched: false,
    disabled: false,
    isValid: true,
    isBlur: false,
    required: true,
    rules: {
      minLength: 10
    },
    invalidityInfo: "",
  },
  addovert: {
    value: 1,
    active: false,
    disabled: false
  },
  kpp: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      minLength: 9
    },
    invalidityInfo: "",
  },
  index: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: false,
    rules: {
      minLength: 6
    },
    invalidityInfo: "",
  },
  // TO-DO: убрать ФИО организации
  surnameOrg: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  nameOrg: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  patronymicOrg: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  ///
  phoneOrg: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      minLength: 10
    },
    invalidityInfo: "",
  },
  fullNameOrg: {
    value: "",
    active: false,
    isTouched: false,
    disabled: false,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  shortNameOrg: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  // TO-DO: убрать должность директора
  positionDirector: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  ///
  clientComment: {
    value: "",
    active: false,
    disabled: true,
    isTouched: true,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  partnerOrganizationName: {
    value: "",
    active: false,
    disabled: true,
    isTouched: true,
    isValid: true,
    isBlur: true,
    required: true,
    invalidityInfo: "",
  },
  fnsCode: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: false,
    rules: {
      minLength: 4
    },
    invalidityInfo: "",
  },

  ////// плательщик
  payerSubjectType: {
    value: "himself", //До #2610 было - "fl"
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  payerName: {
    value: "",
    active: false,
    isTouched: false,
    disabled: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  // payerShortName: {
  //   value: "",
  //   active: false,
  //   disabled: false,
  //   isTouched: false,
  //   isValid: false,
  //   isBlur: true,
  //   required: true,
  //   rules: {empty: true},
  //   invalidityInfo: "",
  // },
  payerInn: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      inn: true,
      minLength: 10
    },
    invalidityInfo: "",
  },
  payerKpp: {
    value: "",
    active: false,
    isTouched: false,
    isValid: false,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 9
    },
    invalidityInfo: "",
  },
  payerPhone: {
    value: "",
    active: false,
    isTouched: false,
    isValid: false,
    disabled: false,
    isBlur: false,
    required: true,
    rules: {
      empty: true,
      minLength: 10
    },
    invalidityInfo: "",
  },
  payerCountryId: {
    value: 193,
    active: false,
    disabled: false,
    isTouched: false,
    isValid: true,
    isBlur: true,
    required: false,
    rules: {},
    invalidityInfo: "",
  },
  payerRegionId: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  payerCity: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  payerAddress: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {
      empty: true,
      cyrillic: true,
    },
    invalidityInfo: "",
  }, 
  payerPostalCode: {
    value: "",
    active: false,
    isTouched: false,
    isValid: true,
    disabled: false,
    isBlur: false,
    required: false,
    rules: {
      minLength: 6
    },
    invalidityInfo: "",
  },
  //////
  separatedAddressBuildingType: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressBuildingValue: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressLocalityType: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressLocalityValue: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressRoadType: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressRoadValue: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressApartmentType: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
  separatedAddressApartmentValue: {
    value: "",
    active: false,
    disabled: false,
    isTouched: false,
    isValid: false,
    isBlur: true,
    required: true,
    rules: {empty: true},
    invalidityInfo: "",
  },
};
