import { Module } from "vuex";

import { RootState } from "@/store/root.interface";

import { IPreApplicationState } from "./preapplication.interfaces";
import {
  STATUSES,
  DEFAULT_COUNTRY,
} from "./preapplication.constants";
import {
  DEFAULT_FORM_CONTROLS,
} from "./preapplication.defaultFormControls";
import {
  DEFAULT_UPLOADS,
  DEFAULT_ANOTHER_UPLOADS
} from "./preapplication.defaultUploads";
import { mutations } from "./preapplication.mutations";
import { getters } from "./preapplication.getters";
import { actions } from "./preapplication.actions";

export const preApplicationState: Module<IPreApplicationState, RootState> = {
  namespaced: true,

  state: {
    isFormValid: null,
    isEdit: null,
    isSubmited: false,
    fieldsReadonlyKeys: [],
    activeTab: 1,
    optionsCountry: [ DEFAULT_COUNTRY ],
    optionsRegion: [],
    optionsPassportDivisions: [],
    formControls: { ...DEFAULT_FORM_CONTROLS },
    partnerManagerId: null,
    filialId: null,
    orderId: null,
    status: null,
    statusId: null,
    products: [],
    managerComment: null,
    creationDate: null,
    errorMessage: null,
    isChangeStatusVisible: false,
    optionsAdditionalStatuses: [],
    scans: [],
    applicationId: null,
    preappPaymentStatus: false,
    typeId: 0,
    uploads: JSON.parse(JSON.stringify(DEFAULT_UPLOADS)),
    anotherUploads: JSON.parse(JSON.stringify(DEFAULT_ANOTHER_UPLOADS)),
    fnsExistingCerts: [],
    skziPreapp: false,
    updStatus: null,
    calendarAppointment: null,
  },

  mutations,
  actions,
  getters
};
