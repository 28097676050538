import ROLES from '@/config/roles';
import { PAGE_SHORT_NAMES } from '@/config/pages';

export type TTableColumns = {
  [key in PAGE_SHORT_NAMES]: {
    [key in ROLES]?: Array<{title: string; part: number;}>
  }
};

const TABLE_COLUMNS: TTableColumns = {
  [PAGE_SHORT_NAMES.app]: {
    [ROLES.Manager]: [
      {title: "№", part: 0.06},
      {title: "Создана", part: 0.06},
      {title: "Статус", part: 0.07},
      {title: "Идентификация", part: 0.09},
      {title: "Оплата", part: 0.06},
      {title: "Дата оплаты", part: 0.07},
      {title: "Организация", part: 0.09},
      {title: "ФИО", part: 0.08},
      {title: "Тип заявителя", part: 0.09},
      {title: "Телефон", part: 0.07},
      {title: "В заказе", part: 0.13},
      {title: 'Страхование', part: 0.07},
      {title: "Итоговая стоимость", part: 0.06},
    ],
    [ROLES.ChiefManager]: [
      {title: "№", part: 0.06},
      {title: "Создана", part: 0.06},
      {title: "Статус", part: 0.07},
      {title: "Идентификация", part: 0.09},
      {title: "Оплата", part: 0.06},
      {title: "Дата оплаты", part: 0.07},
      {title: "Организация", part: 0.09},
      {title: "ФИО", part: 0.08},
      {title: "Тип заявителя", part: 0.08},
      {title: "Телефон", part: 0.07},
      {title: "В заказе", part: 0.1},
      {title: 'Страхование', part: 0.07},
      {title: "Итоговая стоимость", part: 0.06},
      {title: "Менеджер", part: 0.07},
    ],
    [ROLES.ManagerPartner]: [
      {title: "№", part: 0.06},
      {title: "Создана", part: 0.06},
      {title: "Статус", part: 0.07},
      {title: "Идентификация", part: 0.09},
      {title: "Оплата", part: 0.06},
      {title: "Организация", part: 0.09},
      {title: "ФИО", part: 0.08},
      {title: "Тип заявителя", part: 0.1},
      {title: "В заказе", part: 0.13},
      {title: 'Страхование', part: 0.07},
      {title: "Менеджер", part: 0.07},
      {title: "Филиал", part: 0.12},
    ],
  },
  [PAGE_SHORT_NAMES.preapp]: {
    [ROLES.Manager]: [
      {title: "№", part: 0.06},
      {title: "Создана", part: 0.06},
      {title: "Статус", part: 0.07},
      {title: "Организация", part: 0.09},
      {title: "ФИО", part: 0.08},
      {title: "Тип заявителя", part: 0.1},
      {title: "Телефон", part: 0.07},
      {title: "В заказе", part: 0.31},
      {title: 'Страхование', part: 0.07},
      {title: "Итоговая стоимость", part: 0.09},
    ]
  },
  [PAGE_SHORT_NAMES.certificates]: {
    [ROLES.ChiefManager]: [
      {title: "№ заявки", part: 0.1},
      {title: "Статус", part: 0.11},
      {title: "ФИО", part: 0.21},
      {title: "Организация", part: 0.20},
      {title: "ИНН организации", part: 0.14},
      {title: "ИНН заявителя", part: 0.1},
      {title: "Дата выпуска", part: 0.07},
      {title: "Дата окончания", part: 0.07}
    ]
  },
  [PAGE_SHORT_NAMES.revokes]: {
    [ROLES.Manager]: [
      {title: "№", part: 0.08},
      {title: "Создана", part: 0.07},
      {title: "Статус", part: 0.1},
      {title: "Издатель", part: 0.08},
      {title: "Тип заявителя", part: 0.12},
      {title: "Заявитель", part: 0.3},
      {title: "Подтверждена", part: 0.11},
      {title: "Менеджер", part: 0.13}
    ]
  },
  [PAGE_SHORT_NAMES.calendar]: {
    [ROLES.Manager]: [
      {title: "время", part: 0.055},
      {title: "пн", part: 0.135},
      {title: "вт", part: 0.135},
      {title: "ср", part: 0.135},
      {title: "чт", part: 0.135},
      {title: "пт", part: 0.135},
      {title: "сб", part: 0.135},
      {title: "вск", part: 0.135}
    ]
  },
};

export default TABLE_COLUMNS;
