import Vue from 'vue';
import { ActionTree } from 'vuex';
import moment from 'moment';

import { RootState } from '@/store/root.interface';

import {
  getDateNames,
  hhmmToTimestamp,
  timestampToHHmm,
  DDMMyyyyToTimestamp,
  DDMMyyyyToDayIndex,
} from '@/helpers/date';
import { TOAST_OPTIONS } from '@/constants';
import api from './calendar.api';
import {
  ICalendarState,
  TCalendarDayName,
  ICalendarCell,
  ICalendarDataRow,
  ICalendarAppointment,
  INormalizedCalendarResponse,
  IGetCalendarByFilialIdResponse,
} from './calendar.types';
import {
  CALENDAR_APPLICATION_TYPES,
  CALENDAR_DAY_NAMES,
  CALENDAR_LAST_NOT_WORKING_TIMEVALUE,
} from './calendar.config';
import {
  SET_CALENDAR_DATA,
  SET_CALENDAR_APPOINTMENTS,
  ADD_CALENDAR_APPOINTMENT,
  DELETE_CALENDAR_APPOINTMENT,
  SET_CALENDAR_NEW_APPOINTMENT,
  SET_CALENDAR_CURRENT_APPOINTMENT,
} from './calendar.mutations';

const getAllAppointmentsKey = (date: string, time: string) => `${date}_${time}`;

export const actions: ActionTree<ICalendarState, RootState> = {
  createCalendar ({}, args: {
    weekDates: string[], // даты дней недели, для которой создаётся календарь, в формате DD:MM:yyyy
    calendar?: INormalizedCalendarResponse['calendar'], // нормализованные данные из запроса
    workTime?: INormalizedCalendarResponse['workTime'], // общее начало и конец работы филиала в формате HH:mm
    outOfWork?: INormalizedCalendarResponse['outOfWork'], // обед и выходные по дням недели
    workingTime?: INormalizedCalendarResponse['workingTime'], // начало и конец работы по дням недели
  }) {
    const newCalendarData: ICalendarDataRow[] = [];

    /*
      вычисляет, активна ли ячейка или нет - попадает она на обед или выходной
    */
    const isOutOfWork = (weekDay: TCalendarDayName, timeCode: string) => {
      if (!args.outOfWork) return false;
      if (args.outOfWork[weekDay] === null) return true; // weekends
      if (!Array.isArray(args.outOfWork[weekDay]) || args.outOfWork[weekDay]?.length !== 2) return false;

      return (
        hhmmToTimestamp(timeCode) >= hhmmToTimestamp(args.outOfWork[weekDay]![0]) &&
        hhmmToTimestamp(timeCode) < hhmmToTimestamp(args.outOfWork[weekDay]![1])
      );
    };

    /*
      вычисляет, активна ли ячейка или нет - попадает она на нерабочее время в данный день
    */
    const isOutOfWorkingTime = (weekDay: TCalendarDayName, timeCode: string) => {
      if (!args.workingTime) return false;
      if (!Array.isArray(args.workingTime[weekDay]) || args.workingTime[weekDay]?.length !== 2) return false;
      
      return (
        hhmmToTimestamp(timeCode) < hhmmToTimestamp(args.workingTime[weekDay]![0]) ||
        hhmmToTimestamp(timeCode) > hhmmToTimestamp(args.workingTime[weekDay]![1])
      );
    };

    /*
      создаём пустой незаполненный записями календарь
    */
    for (let time = 0; time <= 24 * 60 - 15; time = time + 15) {
      const timeCode = moment(
        (time + new Date().getTimezoneOffset()) * 60 * 1000,
      ).format('HH:mm');

      const initialDaysData: Record<TCalendarDayName, ICalendarCell> =
        CALENDAR_DAY_NAMES.reduce(
          (acc, weekDay, index) => ({
            ...acc,
            [weekDay]: {
              date: args.weekDates[index],
              appointments: null,
              isOutOfWork: isOutOfWork(weekDay, timeCode) || isOutOfWorkingTime(weekDay, timeCode),
            },
          }),
          {} as Record<TCalendarDayName, ICalendarCell>,
        );

      newCalendarData.push({ key: time, timeCode, ...initialDaysData });
    }

    /*
      заполняем календарь записями из запроса
    */
    if (args.calendar) {
      args.calendar.forEach((newRow) => {
        const index = newCalendarData.findIndex(
          ({ timeCode }) => timeCode === newRow.timeCode,
        );
        if (index) {
          const item: ICalendarDataRow = newCalendarData[index];
          const filledDaysData: Record<TCalendarDayName, ICalendarCell> =
            CALENDAR_DAY_NAMES.reduce(
              (acc, weekDay) => ({
                ...acc,
                [weekDay]: {
                  date: item[weekDay].date,
                  appointments: null,
                  isOutOfWork: item[weekDay].isOutOfWork,
                  isAvailable: newRow[weekDay].isAvailable ?? false,
                },
              }),
              {} as Record<TCalendarDayName, ICalendarCell>,
            );

          newCalendarData.splice(index, 1, {
            key: item.key,
            timeCode: item.timeCode,
            ...filledDaysData,
          } as ICalendarDataRow);
        }
      });
    }
    /*
      отфильтровываем записи в период работы филиала
    */
    return newCalendarData.filter(({ timeCode }) => {
      return !args.workTime
        ? true
        : hhmmToTimestamp(timeCode) >= hhmmToTimestamp(args.workTime?.start) &&
            hhmmToTimestamp(timeCode) <= hhmmToTimestamp(args.workTime?.end);
    });
  },

  /*
    нормализует приходящие в запросе данные
  */
  getNormalizedCalendarData({}, args: {
    response: IGetCalendarByFilialIdResponse,
    from: string, // DD.MM.yyyy
    to: string, // DD.MM.yyyy
  }): INormalizedCalendarResponse {
    const calendarDays: Array<{
      calendarDay: TCalendarDayName;
      dateName: string; // DD.MM.yyyy
    }> = [];
    const fromTimestamp = DDMMyyyyToTimestamp(args.from, '.');
    const toTimestamp = DDMMyyyyToTimestamp(args.to, '.');
    for (
      let timestamp = fromTimestamp;
      timestamp <= toTimestamp;
      timestamp += 24 * 3600 * 1000
    ) {
      const dayNumber = new Date(timestamp).getDay();
      const calendarDay =
        CALENDAR_DAY_NAMES[dayNumber === 0 ? 6 : dayNumber - 1]; // getDay возвращает вскр как 0
      const dateName = moment(new Date(timestamp)).format('DD.MM.yyyy');
      calendarDays.push({ calendarDay, dateName });
    }

    const calendar: INormalizedCalendarResponse['calendar'] = [];
    const workStartTimes: string[] = [];
    const workEndTimes: string[] = [];
    const outOfWork: INormalizedCalendarResponse['outOfWork'] = {}; // выходные и обед на каждый день
    const workingTime: INormalizedCalendarResponse['outOfWork'] = {}; // начало и конец рабочего дня на каждый день
    const appointments: INormalizedCalendarResponse['appointments'] = {};

    args.response.days.forEach((day) => {
      const calendarDay: TCalendarDayName | null =
        calendarDays.find(({ dateName }) => dateName === day.date)
          ?.calendarDay ?? null;

      if (!calendarDay) {
        throw new Error('Не смог определить день недели');
      }

      /*
        заполняем outOfWork - выходные и обед по всем дням
      */
      if (day.workHours) {
        workStartTimes.push(day.workHours.workStartTime);
        workEndTimes.push(day.workHours.workEndTime);

        workingTime[calendarDay] = [
          day.workHours.workStartTime,
          day.workHours.workEndTime,
        ];

        if (day.workHours?.timeoffStartTime && day.workHours?.timeoffEndTime) {
          outOfWork[calendarDay] = [
            day.workHours.timeoffStartTime,
            day.workHours.timeoffEndTime,
          ];
        }
      } else {
        workingTime[calendarDay] = null;
        outOfWork[calendarDay] = null;
      }

      /*
        заполняем calendar - записи клиентов по дням и времени
      */
      if (day.timeslots !== null) {
        const dayIndex = DDMMyyyyToDayIndex(day.date, '.');
        const dayName = CALENDAR_DAY_NAMES[dayIndex];
        
        day.timeslots.forEach((timeslot) => {          
          let index = calendar.findIndex(
            ({ timeCode }) => timeCode === timeslot.time,
          );

          if (index < 0) {
            calendar.push({
              timeCode: timeslot.time,
              [CALENDAR_DAY_NAMES[0]]: { isAvailable: true },
              [CALENDAR_DAY_NAMES[1]]: { isAvailable: true },
              [CALENDAR_DAY_NAMES[2]]: { isAvailable: true },
              [CALENDAR_DAY_NAMES[3]]: { isAvailable: true },
              [CALENDAR_DAY_NAMES[4]]: { isAvailable: true },
              [CALENDAR_DAY_NAMES[5]]: { isAvailable: true },
              [CALENDAR_DAY_NAMES[6]]: { isAvailable: true },
            } as (typeof calendar)[number]);

            index = calendar.length - 1;
          }

          const item = { ...calendar[index], [dayName]: { isAvailable: timeslot.isAvailable }};
          calendar.splice(index, 1, item);

          if (timeslot.appointments?.length) {
            appointments[getAllAppointmentsKey(day.date, timeslot.time)] =
              timeslot.appointments?.map((appointment) => ({
                id: appointment.id,
                applicationType: appointment.applicationType,
                officeId: appointment.officeId,
                name: appointment.clientName,
                phone: appointment.clientPhone,
                email: appointment.clientEmail,
              }));
          }
        });
      }
    });

    /*
      минимальное и максимальнео время работы филиала по всем дням
    */
    const minWorkStartTimestamp = Math.min.apply(
      null,
      workStartTimes.map((t) => hhmmToTimestamp(t)),
    );
    const maxWorkEndTimestamp = Math.max.apply(
      null,
      workEndTimes.map((t) => hhmmToTimestamp(t)),
    );

    const start = timestampToHHmm(minWorkStartTimestamp);
    const end = timestampToHHmm(
      maxWorkEndTimestamp - CALENDAR_LAST_NOT_WORKING_TIMEVALUE,
    );

    return { calendar, workTime: { start, end }, outOfWork, appointments, workingTime };
  },

  async getCalendarByFilialId({ dispatch }, args: {
    from: string; // DD.MM.yyyy
    to: string; // DD.MM.yyyy
  }) {
    try {
      const { data, error } = await api.getCalendarByFilialId({
        from: args.from,
        to: args.to,
      });

      if (error || !data) {
        throw new Error(
          `Не удалось загрузить календарь на даты ${args.from} - ${args.to}: ${
            error || 'нет данных'
          }`,
        );
      }

      const { calendar, workTime, outOfWork, appointments, workingTime } = await dispatch('getNormalizedCalendarData', {
        response: data,
        from: args.from,
        to: args.to,
      });

      return { calendar, workTime, outOfWork, appointments, workingTime };
    } catch (err) {
      const error = err as any;
      const errorMessage = JSON.stringify(
        error.response?.data || error.message || 'Неизвестная ошибка',
      );
      Vue.$toast.error(errorMessage, TOAST_OPTIONS.Error);
      return null;
    }
  },

  async calendarInit({ dispatch, commit }, args: {
    dates: Date[];
  }) {
    const from = moment(args.dates[0]).format('DD.MM.yyyy');
    const to = moment(args.dates[6]).format('DD.MM.yyyy');

    const response = await dispatch('getCalendarByFilialId', {
      from,
      to,
    });

    if (response) {
      const calendarData = await dispatch('createCalendar', {
        weekDates: getDateNames(args.dates),
        calendar: response.calendar,
        workTime: response.workTime,
        outOfWork: response.outOfWork,
        workingTime: response.workingTime,
      });

      commit(SET_CALENDAR_DATA, calendarData);
      commit(SET_CALENDAR_APPOINTMENTS, response.appointments);
    }
  },

  async getAppointmentByOrderId({ state }, args: {
    orderId: number;
    applicationType: CALENDAR_APPLICATION_TYPES;
  }) {
    const { data, status, error } = await api.getAppointmentByOrderId({
      orderId: args.orderId,
      applicationType: args.applicationType,
    });

    if (error || !status) {
      Vue.$toast.error(
        `Не удалось загрузить запись для предазявки ${args.orderId}: ${
          error || 'нет данных'
        }`,
        TOAST_OPTIONS.Error,
      );
      return null;
    }

    if (status === 204) return null;

    if (!data) {
      Vue.$toast.error(
        'Не удалось загрузить запись для предазявки ${args.orderId}: нет данных',
        TOAST_OPTIONS.Error,
      );
      return null;
    }

    return data;
  },

  async calendarChangeAppointment({ state, commit }, args: {
    orderId: number;
    applicationType: CALENDAR_APPLICATION_TYPES;
    officeId: number;
    prevAppointment: {
      date: string;
      time: string;
      name: string;
      phone: string;
      email: string;
      comment: string;
    };
    newAppointment: {
      date: string;
      time: string;
      name: string;
      phone: string;
      email: string;
      comment: string;
    };
  }) {
    const { error } = await api.changeAppointment({
      orderId: args.orderId,
      applicationType: args.applicationType,
      officeId: args.officeId,
      newAp: args.newAppointment,
    });

    if (error) {
      Vue.$toast.error(
        `Не удалось изменить запись для предазявки ${args.orderId}: ${
          error || 'нет данных'
        }`,
        TOAST_OPTIONS.Error,
      );
      return { success: false };
    }

    /* удаляем предыдущую запись из таблицы */
    const appointmentKeyDelete = getAllAppointmentsKey(args.prevAppointment.date, args.prevAppointment.time);
    if (state.allAppointments[appointmentKeyDelete]) {
      commit(DELETE_CALENDAR_APPOINTMENT, { appointmentKey: appointmentKeyDelete, id: args.orderId });
    }

    /* вставляем новую запись из таблицы */
    const newAppointment: ICalendarAppointment = {
      id: args.orderId,
      applicationType: args.applicationType,
      officeId: args.officeId,
      name: args.newAppointment.name,
      phone: args.newAppointment.phone,
      email: args.newAppointment.email,
      comment: args.newAppointment.comment,
    };
    const appointmentKey = getAllAppointmentsKey(args.newAppointment.date, args.newAppointment.time);
    commit(ADD_CALENDAR_APPOINTMENT, { appointmentKey, newAppointment })

    Vue.$toast.success('Запись успешно перенесена', TOAST_OPTIONS.Success);
    return { success: true };
  },

  async calendarAddAppointment({ commit }, args: {
    orderId: number;
    applicationType: CALENDAR_APPLICATION_TYPES;
    officeId: number;
    newAppointment: {
      date: string;
      time: string;
      name: string;
      phone: string;
      email: string;
      comment: string;
    };
  }) {
    const { error } = await api.addAppointment({
      orderId: args.orderId,
      applicationType: args.applicationType,
      officeId: args.officeId,
      newAp: args.newAppointment,
    });

    if (error) {
      Vue.$toast.error(
        `Не удалось добавить запись для предазявки ${args.orderId}: ${
          error || 'нет данных'
        }`,
        TOAST_OPTIONS.Error,
      );
      return { success: false };
    }

    /* вставляем новую запись в таблицу */
    const newAppointment: ICalendarAppointment = {
      id: args.orderId,
      applicationType: args.applicationType,
      officeId: args.officeId,
      name: args.newAppointment.name,
      phone: args.newAppointment.phone,
      email: args.newAppointment.email,
      comment: args.newAppointment.comment,
    };
    const appointmentKey = getAllAppointmentsKey(args.newAppointment.date, args.newAppointment.time);
    commit(ADD_CALENDAR_APPOINTMENT, { appointmentKey, newAppointment })

    Vue.$toast.success('Запись успешно добавлена', TOAST_OPTIONS.Success);
    return { success: true };
  },

  async calendarDeleteAppointment({ commit }, args: {
    orderId: number;
    applicationType: CALENDAR_APPLICATION_TYPES;
    date: string;
    time: string;
  }) {
    const { error } = await api.deleteAppointment({
      orderId: args.orderId,
      applicationType: args.applicationType,
    });

    if (error) {
      Vue.$toast.error(
        `Не удалось удалить запись для предазявки ${args.orderId}: ${
          error || 'нет данных'
        }`,
        TOAST_OPTIONS.Error,
      );
      return { success: false };
    }

    /* удаляем запись из таблицы */
    const appointmentKey = getAllAppointmentsKey(args.date, args.time);
    commit(DELETE_CALENDAR_APPOINTMENT, { appointmentKey, id: args.orderId} );

    Vue.$toast.success('Запись успешно удалена', TOAST_OPTIONS.Success);
    return { success: true };
  },

  calendarClearState({ commit }) {
    commit(SET_CALENDAR_DATA, []);
    commit(SET_CALENDAR_APPOINTMENTS, {});
    commit(SET_CALENDAR_NEW_APPOINTMENT, null);
    commit(SET_CALENDAR_CURRENT_APPOINTMENT, null);
  }
};
