

















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { mapActions, mapState, mapGetters } from "vuex";
import download from "downloadjs";

import { InitialApplicationStatuses } from "@/constants/InitialApplicationStatuses";
import { InitialFileTypes } from "@/constants/InitialFileTypes";

import ItemDocument from "@/components/ItemDocument.vue";
import Button from "@/components/UI/Button.vue";
import IconBase from "@/components/UI/IconBase.vue";
import Download from "@/components/UI/icon/Download.vue";
import { IFieldUpload } from "@/store/modules/application/application.interfaces";

@Component({
  computed: {
    ...mapState("application", [
      "isEditUpload",
      "statusId",
      "orderId",
      "isDocumentsCollected",
      "currentCertThumbprint",
      "isUploadFormLoaded",
      "errorMessage",
      "managerId",
      "checkCertInFnsMessage",
      "filialClientFlags",
      "isDocumentsCollected",
      "archived",
    ]),
    ...mapGetters("application", ["getIsDocumentsReadyToUpload"]),
    ...mapGetters("authorizationState", ["isManagerPartner"])
  },
  methods: {
    ...mapActions("application", [
      "downloadHandler",
      "handlerSaveDocuments",
      "inputFileHandler",
      "setCurrentCertThumbprint",
      "fotoCaptureHandler",
      "handlerHeadSign",
      "clearcheckCertInFnsMessage",
      "enableRevocation",
      "signWithIgnore",
      "fetchAnotherUploads",
      "fetchAnotherUpload",
      "transferScansFromPreApp",
      "uploadApplicantSignedDocuments",
      "getApplicantSignedArchiveWithDocs",
      "getApplicantSignedDocument",
      "getApplicantSignedDocsListFromArchive",
      "fetchUploadedRequest",
      "fetchUploadedDocuments"
    ])
  },
  components: { ItemDocument, Button, Download, IconBase }
})
export default class ProposalFormDocument extends Vue {
  @Prop({ type: String }) public title!: string;
  @Prop({ type: String }) public descr!: string;
  @Prop({ type: Boolean, default: false}) public typeDownload!: boolean;
  @Prop({ type: Boolean, default: false}) public typeUpload!: boolean;
  @Prop({ type: Boolean, default: false}) public typeCollected!: boolean;
  @Prop({ type: Boolean, default: false}) public typeAnother!: boolean;
  @Prop({ type: Array, default: () => [] }) public documents!: [];

  private downloadHandler!: (docInfo: {
    orderId: number;
    userId: number;
    typeId: number;
  }) => void;
  private fotoCaptureHandler!: (cameraimg: any) => void;
  private statusId!: number;
  private orderId!: number;
  private managerId!: number;
  private filialClientFlags!: number;
  private clearcheckCertInFnsMessage!: () => void;
  private fetchAnotherUploads!: () => void;
  private fetchAnotherUpload!: ({name, fileId}: {name: string; fileId?: number}) => void;
  private transferScansFromPreApp!: () => void;
  private fetchUploadedDocuments!: () => void;
  private fetchUploadedRequest!: () => void;
  private getApplicantSignedArchiveWithDocs!: () => void;
  private getApplicantSignedDocument!: (fileType: number) => void;
  private getApplicantSignedDocsListFromArchive!: () => void;
  public isDocumentsCollected!: boolean;

  private isUploadFormLoaded!: boolean;
  private modalErrorClose = false;

  private get isDocumentsSending(): boolean {
    return this.statusId === InitialApplicationStatuses.DocumentsSending;
  }

  private get isHeadManagerSignWaiting(): boolean {
    return this.statusId === InitialApplicationStatuses.HeadManagerSign;
  }

  private isFilled(item: any): boolean {
    if (
      item.name.startsWith("another") ||
      ["certificateSigned", "securityInformationSigned"].includes(item.name)
    ) {
      return item.isTouched;
    }
    return item.files && !!item.files.length
  }

  public mounted() {
    if (!this.isDocumentsCollected) {
      this.fetchUploadedDocuments();
    }
    else if((this.filialClientFlags & 0x0002) == 0x0002) {
       this.fetchUploadedRequest();
    }
    if (this.typeUpload) {
      this.transferScansFromPreApp();
    }
    if (this.typeCollected) {
      this.transferScansFromPreApp();
      this.getApplicantSignedDocsListFromArchive();
    }
    if (this.typeAnother) {
      this.fetchAnotherUploads();
    }
  }

  public beforeCloseCheckCertInFNS(evt: any) {
    this.modalErrorClose = true;

    this.clearcheckCertInFnsMessage();

    if (!this.isUploadFormLoaded) {
      evt.stop();
    }
  }

  private dowanloadDocumentZip() {
    const hasExtra = this.documents.some((e: IFieldUpload) => e.isExtra && e.isValid);
    const hasSignedDocs = this.documents.some((e: IFieldUpload) =>
      (e.fileType === InitialFileTypes.ApplicantSignedPrintingForm ||
      e.fileType === InitialFileTypes.ApplicantSignedSecurityInstructionForm ||
      e.fileType === InitialFileTypes.PrintingFormSignature) &&
      e.isCollected);

    this.downloadHandler({
      orderId: this.orderId,
      userId: this.managerId,
      typeId: InitialFileTypes.DocumentsZip
    });

    hasExtra && this.downloadHandler({
      orderId: this.orderId,
      userId: this.managerId,
      typeId: InitialFileTypes.ExtraDocs
    });

    hasSignedDocs && this.getApplicantSignedArchiveWithDocs();
  }

  private onFotoCapture(cameraimg: any) {
    console.log("foto saving...");
    this.fotoCaptureHandler(cameraimg);
    // this.$modal.hide("webcam_modal");
  }

   private async handleClickItemDocument(item: any) {
    if (item.name.startsWith("another")) {
      this.fetchAnotherUpload({name: item.name});
    } else if (["certificateSigned", "securityInformationSigned"].includes(item.name) && item.isCollected) {
      this.getApplicantSignedDocument(item.fileType);
    } else if (item.files && item.files.length) {
      const mimeType = item.files[0].type;
      if (mimeType.match(/(jpg|jpeg|png|gif|pdf|PDF|JPG|PNG)/)) {
        const url = URL.createObjectURL(item.files[0]);
        window.open(url, '_blank');
      } else {
        download(item.files[0], decodeURI(item.files[0].name), mimeType);
      }
    }
  }
}
