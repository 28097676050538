





















































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

import { getRole } from '@/services/api/fetch';
import ROLES from "@/config/roles";
import { PATHS } from '@/router';
import { ORGANIZATION_TYPE_IDS } from "@/constants/organization";

export default Vue.extend({
    name: 'Header',

    data() {
        return {
            PATHS,
        };
    },

    computed: {
        ...mapGetters('revokeListState', ['getIsRevokesAllowed']),
        ...mapGetters('preapplicationList', ['newPreAppNum']),

        isCertificatesVisible() {
            const role = getRole();
            return role in ROLES && [ROLES.ChiefManager].includes(role as ROLES);
        },

        isRevokesVisible() {
            if (this.getIsRevokesAllowed === null) {
                const role = getRole();
                const isAllowed = role in ROLES && 
                    [ROLES.ChiefManager, ROLES.Manager].includes(role as ROLES);
                
                const filialInfo = this.$store.state.filialInfo;
                const isRevokesAllowed =  isAllowed && 
                    (filialInfo?.options.allowedRevocation ?? false);
    
                this.$store.commit(`revokeListState/SET_IS_REVOKES_ALLOWED`, isRevokesAllowed);
            }
                
            return this.getIsRevokesAllowed;
        },

        allowCalendar() {
            const role = getRole();
            const isAllowed = role in ROLES && [ROLES.ChiefManager, ROLES.Manager, ROLES.Admin].includes(role as ROLES);
            const allowCalendar =
                (this.$store.state.filialInfo &&
                this.$store.state.filialInfo.organizationTypeId === ORGANIZATION_TYPE_IDS.Agent &&
                this.$store.state.filialInfo.options.allowCalendar &&
                isAllowed) ?? false;
            return allowCalendar;
        },
    },

    methods: {
        ...mapActions('preapplicationList', ['getNewPreAppNum']),
    },

    async mounted() {
        this.getNewPreAppNum();
    }
});
