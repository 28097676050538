import {GetterTree} from 'vuex';

import { RootState } from '@/store/root.interface';
import { DDMMyyyyToTimestamp } from '@/helpers/date';

import { ICalendarState, ICalendarDataRow } from './calendar.types';
import { CALENDAR_DAY_NAMES } from './calendar.config'; 

export const getters: GetterTree<ICalendarState, RootState> = {
  calendarDataWithAppointments(state) {
    const calendarData: ICalendarDataRow[] = JSON.parse(
      JSON.stringify(state.calendarData),
    );
    Object.entries(state.allAppointments).forEach(([key, value]) => {
      const date = key.split('_')[0];
      const time = key.split('_')[1];
      const row = calendarData.find(({ timeCode }) => timeCode === time);
      const timestamp = DDMMyyyyToTimestamp(date, '.');
      const dayNumber = new Date(timestamp).getDay();
      const calendarDay =
        CALENDAR_DAY_NAMES[dayNumber === 0 ? 6 : dayNumber - 1];
      if (calendarDay && row && row[calendarDay]) {
        row[calendarDay].appointments = value;
      }
    });
    const calendarDataWithAppointments = calendarData.map((row) => {
      return [
        row.timeCode,
        { ...row.monday, timeCode: row.timeCode },
        { ...row.tuesday, timeCode: row.timeCode },
        { ...row.wednesday, timeCode: row.timeCode },
        { ...row.thursday, timeCode: row.timeCode },
        { ...row.friday, timeCode: row.timeCode },
        { ...row.saturday, timeCode: row.timeCode },
        { ...row.sunday, timeCode: row.timeCode },
      ];
    });
    return calendarDataWithAppointments;
  }
};
