import Vue from "vue";
import Router from "vue-router";
import cookie from "js-cookie";
import store from "./store";
import {setAccessToken, isAuthenticate} from "@/services/api";
import { getNameId } from "@/services/api/fetch";

import Login from "@/views/Login.vue";
import {
    CLEAR_APPLICATION_LIST,
    SET_APPLICATIONS_LIST
} from "./constants/mutations-type";
import {
    SET_IS_REVOKES_ALLOWED
} from '@/store/modules/revoke-list/revoke-list.mutations';
import { getRole } from "./services/api/fetch";
import ROLES from "@/config/roles";
import { ORGANIZATION_TYPE_IDS } from "./constants/organization";
import {hideBitrixButton} from "@/utils";

Vue.use(Router);

export enum PATHS {
    Login = '/login',
    Logout = '/logout',
    NotFound = '/403',
    About = '/about',
    Home = '/',
    Archive = '/archive',
    PasswordChange = '/passwordChange',
    Profile = '/profile',
    Application = '/application',
    Preapplication = '/preapplication',
    Revoke = '/revoke',
    Certificate = '/certificate',
    Calendar = '/calendar',
}

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/login",
            name: "login",
            component: Login,
            beforeEnter: (to, from, next) => {
                if (store.state.updateExists) {
                    store.dispatch("refreshApp");
                }

                if (isAuthenticate()) {
                    next({path: "/"});
                } else {
                    next();
                }
            }
        },
        {
            path: "/about",
            name: "about",
            component: () => import("@/views/About.vue")
        },
        {
            path: "/",
            name: "home",
            meta: {requiresAuth: true},
            component: () => import("@/views/Home.vue")
        },
        {
            path: "/archive",
            name: "archive",
            meta: {requiresAuth: true},
            component: () => import("@/views/Home.vue")
        },
        {
            path: "/passwordChange",
            name: "passwordChange",
            meta: {requiresAuth: true},
            component: () => import("@/views/PasswordChange.vue")
        },
        {
            path: "/profile",
            name: "profile",
            meta: {requiresAuth: true},
            component: () => import("@/views/Profile.vue")
        },
        {
            path: "/application/create",
            name: "create-application",
            meta: {requiresAuth: true},
            component: () => import("@/views/Application.vue")
        },
        {
            path: "/application/:orderId",
            name: "application",
            meta: {requiresAuth: true},
            component: () => import("@/views/Application.vue"),
        },
        {
            path: "/preapplication/list",
            name: "preapp-home",
            meta: {requiresAuth: true},
            component: () => import("@/views/PreAppHome.vue")
        },
        {
            path: "/preapplication/:orderId",
            name: "preapplication",
            meta: {requiresAuth: true},
            component: () => import("@/views/PreApplication.vue"),
        },
        {
            path: "/revoke/list",
            name: "revoke-list",
            meta: {requiresAuth: true},
            component: () => import("@/views/RevokeList.vue"),
            beforeEnter: async (to, from, next) => {
                const role = getRole();
                const isRevokesAllowed = role in ROLES && [ROLES.ChiefManager, ROLES.Manager].includes(role as ROLES) &&
                                         (store.state.filialInfo?.options.allowedRevocation ?? false);
                store.commit(`revokeListState/${SET_IS_REVOKES_ALLOWED}`, isRevokesAllowed);
                if (isRevokesAllowed) next();
                else next('/403');
            }
        },
        {
            path: "/revoke/:orderId",
            name: "revoke",
            meta: {requiresAuth: true},
            component: () => import("@/views/Revoke.vue"),
            beforeEnter: async (to, from, next) => {
                const role = getRole();
                const isRevokesAllowed = role in ROLES && [ROLES.ChiefManager, ROLES.Manager].includes(role as ROLES) &&
                                         (store.state.filialInfo?.options.allowedRevocation ?? false);
                store.commit(`revokeListState/${SET_IS_REVOKES_ALLOWED}`, isRevokesAllowed);
                if (isRevokesAllowed) next();
                else next('/403');
            }
        },
        {
            path: "/certificate/list",
            name: "certificates",
            meta: {requiresAuth: true},
            component: () => import("@/views/CertificateList.vue"),
            beforeEnter: async (to, from, next) => {
                const role = getRole();
                const isAllowed = role in ROLES && [ROLES.ChiefManager].includes(role as ROLES);
                isAllowed ? next() : next('/403');
            }
        },
        {
            path: PATHS.Calendar,
            name: "calendar",
            meta: {requiresAuth: true},
            component: () => import("@/views/Calendar.vue"),
            beforeEnter: async (to, from, next) => {
                const role = getRole();
                const isAllowed = role in ROLES && [ROLES.ChiefManager, ROLES.Manager, ROLES.Admin].includes(role as ROLES);
                const allowCalendar =
                    (store.state.filialInfo &&
                    store.state.filialInfo.organizationTypeId === ORGANIZATION_TYPE_IDS.Agent &&
                    store.state.filialInfo.options.allowCalendar &&
                    isAllowed) ?? false;
                if (allowCalendar) next();
                else next('/403');
            },
        },
        {
            path: "/logout",
            name: "logout",
            beforeEnter: (to, from, next) => {
                hideBitrixButton();
                store.commit(`applicationListState/${CLEAR_APPLICATION_LIST}`);
                store.commit(`applicationListState/${SET_APPLICATIONS_LIST}`, []);
                store.commit(`revokeListState/${SET_IS_REVOKES_ALLOWED}`, null);
                store.commit('SET_FILIAL_INFO', null);
                store.dispatch("application/clearFromControlsHandler");
                store.dispatch("products/resetProducts");
                store.dispatch("authorizationState/clear");
                store.dispatch("filterState/clearPresets");
                setAccessToken(null);
                cookie.remove("_rt");
                next({path: "/login"});
            }
        },
        {
            path: "/403",
            name: "403",
            meta: {requiresAuth: true},
            component: () => import("@/views/NotFound.vue"),
            props: {default: true, typeError: "403"}
        },
        {
            path: "*",
            name: "404",
            meta: {requiresAuth: true},
            component: () => import("@/views/NotFound.vue")
        }
    ]
});

router.beforeEach(async (to, from, next) => {
    if (store.state.updateExists) {
        store.dispatch("refreshApp");
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticate()) {
            hideBitrixButton();
            next({
                path: "/login",
                query: {redirect: to.fullPath}
            });
        } else {
            // Загружаем filialInfo для всех роутов
            if (store.state.filialInfo === null) {
                const managerId = getNameId();
                const filialInfo = await store.dispatch('revokeListState/getFilialByManager', managerId);
                store.commit('SET_FILIAL_INFO', filialInfo);
            }

            next();
        }
    } else {
        next();
    }
});

export default router;
