






















































































































import Vue from "vue";
import { mapActions, mapState, mapGetters } from "vuex";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import PreAppFormRequest from "@/components/PreAppFormRequest.vue";
import Button from "@/components/UI/Button.vue";
import ArrowLeft from "@/components/UI/icon/ArrowLeft.vue";
import Pencil from "@/components/UI/icon/Pencil.vue";
import Comment from "@/components/UI/icon/Comment.vue";
import IconBase from "@/components/UI/IconBase.vue";
import ModalWithField from "@/components/ModalWithField.vue";

import { scrollToInvalidFormItem } from "@/helpers/scrollToInvalidFormItem";
import { IDENTIFICATION_NOTIFICATIONS } from "@/store/modules/preapplication/preapplication.constants";
import { PRODUCT_BINDS } from "@/store/modules/products/products.constants";
import { ICalendarData } from "./Calendar.vue";
import { getRole } from '@/services/api/fetch';
import ROLES from "@/config/roles";
import { ORGANIZATION_TYPE_IDS } from "@/constants/organization";
import { CALENDAR_APPLICATION_TYPES } from "@/store/modules/calendar/calendar.config";

export default Vue.extend({
  name: "PreApplication",

  components: {
    PreAppFormRequest,
    Button,
    ArrowLeft,
    Pencil,
    IconBase,
    Comment,
    PageLayout,
    Header,
    Footer,
    ModalWithField
  },

  data() {
    return {
      identificationNotifications: Object.values(IDENTIFICATION_NOTIFICATIONS),
      showTooltip: false,
    }
  },

  computed: {
    ...mapState("preApplicationState", [
      "isEdit",
      "activeTab",
      "managerComment",
      "statusId",
      "orderId",
      "isFormValid",
    ]),
    ...mapGetters("preApplicationState", [
      "isIdentification",
      "isIdentificationClosed",
      "correctManagerComment",
      "hasTariffLicense",
      "disabledClickReadyForUpd",
      "updStatusTitle",
      "disabledChangePreappWithLicense",
    ]),
    ...mapGetters("authorizationState", ["isManagerPartner"]),
    ...mapGetters("productsState", [
      "getProductsSelected",
      "getHasTariffKSKPEP",
      "getHasTariffInsurance",
      "getHasTariffToken",
    ]),
  },

  methods: {
    ...mapActions("preApplicationState", [
      "clearFromControlsHandler",
      "fetchPreApplication",
      "createApplication",
      "changeStatus",
      "isGoodToChangeStatus",
      "isGoodToCreateApp",
      "editPreApp",
      "checkValidity",
      "setActiveTab",
      "clearManagerComment",
      "clearPreAppType",
      "approveIdentification",
      "declainIdentification",
      "cleanAllUploads",
      "readyForUpd",
      "defineAddressFields",
      "defineLicenseFields",
      "setNotRequiredLicenseFields",
      "defineTokenFields",
      "setNotRequiredTokenFields",
    ]),
     ...mapActions("productsState", ["resetProducts"]),
     ...mapActions("comments", ["getLastComment", "clearCommentsState"]),
     ...mapActions("calendarState", ["getAppointmentByOrderId"]),

    createAppHandleClick() {
      if (this.isEdit) {
        this.$modal.show("dialog", {
          title: "ВНИМАНИЕ",
          text: "Предзаявка находится в режиме редактирования.<br />" + 
            "Перед созданием заявки сохраните или отмените сделанные изменения<br />",
          buttons: [{title: "Ок"}]
        });
      } else {
        this.checkValidity();
        if (this.isFormValid) {
          this.createApplication();
        } else {
          this.setActiveTab(1);
          scrollToInvalidFormItem();
        }
      }
    },

    createLicenseHandleClick() {
      if (this.isEdit) {
        this.$modal.show("dialog", {
          title: "ВНИМАНИЕ",
          text: "Предзаявка находится в режиме редактирования.<br />" + 
            "Перед формированием лицензии сохраните или отмените сделанные изменения<br />",
          buttons: [{title: "Ок"}]
        });
      } else {
        this.checkValidity();
        if (this.isFormValid) {
          this.readyForUpd();
        } else {
          this.setActiveTab(1);
          scrollToInvalidFormItem();
        }
      }
    },
  },

  watch: {
    managerComment() {
      if (this.managerComment !== null) {
        this.getLastComment(this.managerComment);
      }
    },

    getProductsSelected() {
      const hasTariffInsurance = this.getHasTariffInsurance;
      this.defineAddressFields(hasTariffInsurance);

      if (this.getHasTariffToken) {
        this.defineTokenFields();
        this.setNotRequiredTokenFields();
      }
    },

    orderId() {
      if (
        !this.isIdentification &&
        !this.disabledClickReadyForUpd &&
        this.hasTariffLicense
      ) {
        this.defineLicenseFields();
        this.setNotRequiredLicenseFields();
      }
    },
  },

  async mounted() {
    const { orderId } = this.$route.params;
    if (orderId) {
      const promises = [
        this.fetchPreApplication(orderId),
      ];

      const role = getRole();
      const isAllowed = role in ROLES && [ROLES.ChiefManager, ROLES.Manager, ROLES.Admin].includes(role as ROLES);
      const allowCalendar =
        (this.$store.state.filialInfo &&
        this.$store.state.filialInfo.organizationTypeId === ORGANIZATION_TYPE_IDS.Agent &&
        this.$store.state.filialInfo.options.allowCalendar &&
        isAllowed) ?? false;

      if (allowCalendar) {
        promises.push(this.getAppointmentByOrderId({ orderId, applicationType: CALENDAR_APPLICATION_TYPES.Preapplication }));
      }

      await Promise.allSettled(promises).then((responses) => {
        if (responses.length === 2 && responses[1].status === 'fulfilled') {
          if (!!responses[1].value) {
            const {
              appointmentDate,
              appointmentTime,
              clientEmail,
              clientName,
              clientPhone,
              comment,
              applicationId,
              applicationType,
            } = responses[1].value;
            const calendarAppointment = {
              orderId: applicationId,
              applicationType,
              date: appointmentDate,
              time: appointmentTime,
              name: clientName,
              phone: clientPhone,
              email: clientEmail,
              comment,
            } as NonNullable<ICalendarData['editingAppointment']>
            this.$store.commit('preApplicationState/SET_CALENDAR_APPOINTMENT', calendarAppointment);
          } else {
            this.$store.commit('preApplicationState/SET_CALENDAR_APPOINTMENT', null);
          }
        }
      });
    }
  },

  destroyed() {
    this.clearFromControlsHandler();
    this.resetProducts();
    this.clearManagerComment();
    this.clearCommentsState();
    this.clearPreAppType();
    this.cleanAllUploads();
    this.changeStatus(false);
    this.$store.commit('preApplicationState/SET_CALENDAR_APPOINTMENT', null);
  }
})
