import Vue from "vue";
import Vuex, {StoreOptions} from "vuex";

import profile from "./modules/profile";
import {application} from "./modules/application";
import {applicationListState} from "./modules/application-list";
import {preapplicationList} from "./modules/preapplication-list";
import {preApplicationState} from "./modules/preapplication";
import { revokeState } from "./modules/revoke";
import { revokeListState } from "./modules/revoke-list";
import {authorizationState} from "./modules/authorization";
import {productsState} from "./modules/products";
import {cert} from "./modules/cert";
import {comments} from "./modules/comments";
import { tableState } from "./modules/table";
import { certificateListState } from './modules/certificate-list';

import {RootState} from "./root.interface";
import {actions} from "./root.actions";
import {mutations} from "./root.mutations";
import {changePassword} from "@/store/modules/change-password";
import { filterState } from "./modules/filter";
import { cryptoState } from './modules/crypto';
import { smsState } from "./modules/sms";
import { calendarState } from './modules/calendar/calendar.state';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    strict: process.env.NODE_ENV !== "production",
    state: {
        updateExists: false,
        registration: null,
        filialInfo: null,
    },
    actions,
    mutations,
    modules: {
        authorizationState,
        application,
        applicationListState,
        preApplicationState,
        preapplicationList,
        revokeState,
        revokeListState,
        certificateListState,
        profile,
        productsState,
        cert,
        changePassword,
        comments,
        tableState,
        filterState,
        cryptoState,
        smsState,
        calendarState,
    }
};

export default new Vuex.Store<RootState>(store);
