
















import Vue, { PropType } from 'vue';

import { ICalendarCell } from '@/store/modules/calendar/calendar.types';

export default Vue.extend({
  name: 'CalendarCell',
  props: {
    cell: Object as PropType<ICalendarCell>,
  },
});
