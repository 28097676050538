































import Vue from 'vue';
import Datepicker from "vuejs-datepicker";
import { ru } from "vuejs-datepicker/dist/locale";

import {
  getWeekCountByDate,
  getDatesByWeekDate,
  getDateNames,
} from '@/helpers/date';

interface IWeekPickerProps {
  dates: Date[];
}

export default Vue.extend<any, any, any, IWeekPickerProps>({
  name: 'WeekPicker',

  components: {
    Datepicker,
  },

  props: {
    dates: Array,
  },

  data() {
    return {
      language: ru,
    };
  },

  computed: {
    getFormat() {
      const _dates = this.dates.map((d: Date) => new Date(d));
      const count = getWeekCountByDate(_dates[0]);
      const dateNames = getDateNames(_dates);
      return `неделя ${count} (c ${dateNames[0]} по ${dateNames[6]})`;
    },
  },

  methods: {
    inputHandler(date: Date) {
      const _dates = getDatesByWeekDate(date);
      this.$emit('week-picker-change', _dates);
    },

    handleDecrease(dates: Date[]) {
      return dates.map((d) => new Date(d.getTime() - 7 * 24 * 3600 * 1000));
    },

    handleIncrease(dates: Date[]) {
      return dates.map((d) => new Date(d.getTime() + 7 * 24 * 3600 * 1000));
    },
  },
});
