import {MutationTree} from "vuex";

import {validation} from "@/services";

import {
  IField,
  IFormControls,
  IPreApplicationState,
  TPayerType,
  TTypeEntreName,
  TUploadName
} from "./preapplication.interfaces";
import {PAYER_TYPES, TYPE_ENTRES} from "./preapplication.constants";
import {DEFAULT_FORM_CONTROLS} from "./preapplication.defaultFormControls";
import {DEFAULT_ANOTHER_UPLOADS, DEFAULT_UPLOADS} from "./preapplication.defaultUploads";
import {UpdRequestStatuses} from "@/constants/UpdRequestStatuses";
import { ICalendarAppointment } from "../calendar/calendar.types";

export const SET_STATUS = "SET_STATUS";
export const SET_UPLOAD = "SET_UPLOAD";
export const SET_IS_EDIT = "SET_IS_EDIT";
export const SET_ORDER_ID = "SET_ORDER_ID";
export const SET_DISABLED = "SET_DISABLED";
export const CLEAR_REGIONS = "CLEAR_REGIONS";
export const SET_STATUS_ID = "SET_STATUS_ID";
export const SET_FILIAL_ID = "SET_FILIAL_ID";
export const REMOVE_UPLOAD = "REMOVE_UPLOAD";
export const CLEAN_UPLOADS = "CLEAN_UPLOADS";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const NEXT_ACTIVE_TAB = "NEXT_ACTIVE_TAB";
export const SET_IS_SUBMITED = "SET_IS_SUBMITED";
export const SET_PREAPP_TYPE = "SET_PREAPP_TYPE";
export const SET_FORM_CONTROL = "SET_FORM_CONTROL";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_CREATION_DATE = "SET_CREATION_DATE";
export const SET_IS_FORM_VALID = "SET_IS_FORM_VALID";
export const SET_OPTIONS_REGION = "SET_OPTIONS_REGION";
export const SET_TYPE_SELECTION = "SET_TYPE_SELECTION";
export const SET_MANAGERCOMMENT = "SET_MANAGERCOMMENT";
export const SET_APPLICATION_ID = "SET_APPLICATION_ID";
export const SET_PREAPP_PAYMENT = "SET_PREAPP_PAYMENT";
export const SET_ANOTHER_UPLOAD = "SET_ANOTHER_UPLOAD";
export const SET_OPTIONS_COUNTRY = "SET_OPTIONS_COUNTRY";
export const CLEAR_FORM_CONTROLS = "CLEAR_FORM_CONTROLS";
export const SET_PARTNERMANAGER_ID = "SET_PARTNERMANAGER_ID";
export const CLEAN_ANOTHER_UPLOADS = "CLEAN_ANOTHER_UPLOADS";
export const SET_PREAPP_FILIAL_COMMENT = "SET_PREAPP_FILIAL_COMMENT";
export const SET_CHANGE_STATUS_VISIBILITY = "SET_CHANGE_STATUS_VISIBILITY";
export const SET_PREAPP_ADDITIONAL_STATUSES = "SET_PREAPP_ADDITIONAL_STATUSES";
export const SET_FNS_EXISTING_CERTS = "SET_FNS_EXISTING_CERTS";
export const DEFINE_PAYER_FIELDS = "DEFINE_PAYER_FIELDS";
export const SET_SKZI_PREAPP = "SET_SKZI_PREAPP";
export const SET_UPD_STATUS = "SET_UPD_STATUS";
export const DEFINE_ADDRESS_FIELDS = "DEFINE_ADDRESS_FIELDS";
export const DEFINE_LICENSE_FIELDS = "DEFINE_LICENSE_FIELDS";
export const NOT_REQUIRED_LICENSE_FIELDS = "NOT_REQUIRED_LICENSE_FIELDS";
export const RESET_READONLY_FIELDS_KEY = "RESET_READONLY_FIELDS_KEY";
export const SET_READONLY_FIELDS_KEY_ALL = "SET_READONLY_FIELDS_KEY_ALL";
export const SET_PASSPORT_DIVISION = "SET_PASSPORT_DIVISION";
export const SET_OPTIONS_PASSPORT_DIVISIONS = "SET_OPTIONS_PASSPORT_DIVISIONS";
export const CLEAR_OPTIONS_PASSPORT_DIVISIONS = "CLEAR_OPTIONS_PASSPORT_DIVISIONS";
export const SET_CALENDAR_APPOINTMENT = 'SET_CALENDAR_APPOINTMENT';
export const DEFINE_TOKEN_FIELDS = "DEFINE_TOKEN_FIELDS";
export const NOT_REQUIRED_TOKEN_FIELDS = "NOT_REQUIRED_TOKEN_FIELDS";

export const mutations: MutationTree<IPreApplicationState> = {
  [CLEAR_FORM_CONTROLS](state) {
    state.formControls = { ...DEFAULT_FORM_CONTROLS };
    state.isFormValid = null;
    state.isEdit = null;
    state.partnerManagerId = null;
    state.filialId = null;
    state.orderId = null;
    state.status = null;
    state.statusId = null;
    state.products = [];
    state.managerComment = null;
    state.creationDate = null;
    state.activeTab = 1;
    state.errorMessage = null;
  },
  [CLEAR_REGIONS](state) {
    state.optionsRegion = [];
  },
  [SET_TYPE_SELECTION](state: any, val: string) {
    state.formControls.typeEntre.value = val;
    Object.keys(state.formControls).forEach(key => {
      const typeEntreObj = TYPE_ENTRES.find((t :any) => t.name === val);
      if (
        !key.startsWith('separatedAddress') &&
        !['cityReal', 'addressReal', 'cityLaw', 'addressLaw'].includes(key) &&
        (!key.startsWith('payer') || key === 'payerSubjectType')
      ) {
        if (
          !!typeEntreObj && (key === "typeEntre" || typeEntreObj.inputFields.includes(key))
        ) {
          state.formControls[key].active = true;
        } else {
          state.formControls[key].active = false;
        }
      }
    });
  },
  [SET_ERROR_MESSAGE](state, val: string | null | string[]) {
    state.errorMessage = val;
  },
  [SET_FORM_CONTROL](state: any, { control, name }: any) {
    state.formControls[name] = { ...control };
  },
  [SET_ACTIVE_TAB](state, num: number) {
    state.activeTab = num;
  },
  [NEXT_ACTIVE_TAB](state) {
    const num = state.activeTab + 1;
    if (num <= 2) {
      state.activeTab = num;
    }
  },
  [SET_IS_FORM_VALID](state, val: boolean) {
    state.isFormValid = val;
  },
  [SET_OPTIONS_COUNTRY](state, country: []) {
    state.optionsCountry = [...country];
  },
  [SET_OPTIONS_REGION](state, region: []) {
    state.optionsRegion = [...region];
  },
  [SET_OPTIONS_PASSPORT_DIVISIONS](state, passportDivisions: []) {
    state.optionsPassportDivisions = [
      {label: "Ввести вручную", value: null}, 
      ...passportDivisions
    ];
  },
  [SET_PASSPORT_DIVISION](state, val: string) {
    state.formControls['passportDivision'].isValid = true;
    state.formControls['passportDivision'].value = val;
  },
  [CLEAR_OPTIONS_PASSPORT_DIVISIONS](state) {
    state.optionsPassportDivisions = [];
  },
  [SET_ORDER_ID](state, val: number | null) {
    state.orderId = val;
  },
  [SET_IS_EDIT](state, val: boolean | null) {
    state.isEdit = val;
  },
  [SET_DISABLED](state: any, toggle: boolean = false) {
    Object.keys(state.formControls).forEach(nameControl => {
      const control = { ...state.formControls[nameControl] };
      if (!state.fieldsReadonlyKeys.includes(nameControl)) {
        control.disabled = toggle;
        state.formControls[nameControl] = { ...control };
      }
    });
  },
  [SET_IS_SUBMITED](state, val: boolean | null){
    state.isSubmited=val;
  },
  [SET_STATUS](state, val: string) {
    state.status = val;
  },
  [SET_STATUS_ID](state, val: number | null) {
    state.statusId = val;
  },
  [SET_CREATION_DATE](state, date: Date | string | null) {
    state.creationDate = date;
  },
  [SET_MANAGERCOMMENT](state, val: string | null) {
    state.managerComment = val;
  },
  [SET_PARTNERMANAGER_ID](state, payload: number | null) {
    state.partnerManagerId = payload;
  },
  [SET_FILIAL_ID](state, payload: number | null) {
    state.filialId = payload;
  },
  [SET_CHANGE_STATUS_VISIBILITY](state,payload: boolean | null){
    state.isChangeStatusVisible = payload;
  },
  [SET_PREAPP_ADDITIONAL_STATUSES](state,payload : []){
    state.optionsAdditionalStatuses = [...payload];
  },
  [SET_PREAPP_FILIAL_COMMENT](state,payload: string|null){
    state.managerComment = payload;
  },
  [SET_APPLICATION_ID](state, id: number) {
    state.applicationId = id;
  },
  [SET_PREAPP_PAYMENT](state, status: boolean) {
    state.preappPaymentStatus = status;
  },
  [SET_PREAPP_TYPE](state, typeId: number) {
    state.typeId = typeId;
  },
  [SET_UPLOAD](state, {name, upload}: {name: TUploadName, upload: any}) {
    state.uploads[name] = {...upload};
  },
  [REMOVE_UPLOAD](state, name: TUploadName) {
    state.uploads[name] = null;
  },
  [SET_ANOTHER_UPLOAD](state: any, { name, upload }: any) {
    state.anotherUploads[name] = { ...upload };
  },
  [CLEAN_UPLOADS](state: any) {
    state.uploads = JSON.parse(JSON.stringify(DEFAULT_UPLOADS));
  },
  [CLEAN_ANOTHER_UPLOADS](state: any) {
    state.anotherUploads = JSON.parse(JSON.stringify(DEFAULT_ANOTHER_UPLOADS));
  },
  [SET_FNS_EXISTING_CERTS](state, fnsExistingCerts: any[]) {
    state.fnsExistingCerts = JSON.parse(JSON.stringify(fnsExistingCerts));
  },
  [SET_READONLY_FIELDS_KEY_ALL](state, keys: string[]) {
    state.fieldsReadonlyKeys = [...state.fieldsReadonlyKeys, ...keys];
  },
  [RESET_READONLY_FIELDS_KEY](state) {
    state.fieldsReadonlyKeys = [];
  },

  ////// определяет, какие поля плательщика показывать, какие обязательные и валидирует их
  [DEFINE_PAYER_FIELDS](state: any, payerSubjectType: TPayerType) {
    Object.keys(state.formControls).forEach((controlName: string) => {
      if (controlName.startsWith("payer")) {
        if ((PAYER_TYPES[payerSubjectType].activeFields as any).includes(controlName)) {
          state.formControls[controlName].active = true;
        } else {
          state.formControls[controlName].active = false;
        }

        if ((PAYER_TYPES[payerSubjectType].requiredFields as any).includes(controlName)) {
          state.formControls[controlName].required = true;
          state.formControls[controlName].rules.empty = true;
        } else {
          state.formControls[controlName].required = false;
          state.formControls[controlName].rules.empty = false;
        }

        if (controlName === "payerInn") {
          state.formControls[controlName].rules.minLength = state.formControls["payerSubjectType"].value === "ur" ? 10 : 12;
        }

        const {isValid} = validation(state.formControls[controlName].value, state.formControls[controlName].rules);
        state.formControls[controlName].isValid = isValid;
      }
    });
  },
  [SET_SKZI_PREAPP](state: any, skziPreapp: boolean) {
    state.skziPreapp = skziPreapp
  },
  [SET_UPD_STATUS](state: any, updStatus: UpdRequestStatuses | null) {
    state.updStatus = updStatus
  },
  ////// определяет, какие поля адреса показывать - раздельные или единые, какие обязательные и валидирует их
  [DEFINE_ADDRESS_FIELDS](state: any, {subjectType, hasTariffInsurance}: {subjectType: TTypeEntreName, hasTariffInsurance: boolean}) {
    const typeEntreObj = TYPE_ENTRES.find((t :any) => t.name === subjectType);

    Object.keys(state.formControls).forEach((controlName: string) => {
      if (controlName.startsWith("separatedAddress")) {
        if (hasTariffInsurance) {
          state.formControls[controlName].active = true;
          state.formControls[controlName].required = !typeEntreObj?.notRequiredFields.includes(controlName);
          state.formControls[controlName].rules.empty = !typeEntreObj?.notRequiredFields.includes(controlName);
        } else {
          state.formControls[controlName].active = false;
          state.formControls[controlName].required = false;
          state.formControls[controlName].rules.empty = false;
        }
      }

      if (['cityReal', 'addressReal', 'cityLaw', 'addressLaw'].includes(controlName)) {
        if (hasTariffInsurance) {
          state.formControls[controlName].active = false;
        } else {
          state.formControls[controlName].active = typeEntreObj?.inputFields.includes(controlName);
        }

        if (state.formControls[controlName].active) {
          state.formControls[controlName].required = !typeEntreObj?.notRequiredFields.includes(controlName);
          state.formControls[controlName].rules.empty = !typeEntreObj?.notRequiredFields.includes(controlName);
        }
      }

      if (controlName === 'index') {
        state.formControls[controlName].active = hasTariffInsurance || typeEntreObj?.inputFields.includes(controlName);
        state.formControls[controlName].required = hasTariffInsurance || !typeEntreObj?.notRequiredFields.includes(controlName);
        state.formControls[controlName].rules.empty = hasTariffInsurance || !typeEntreObj?.notRequiredFields.includes(controlName);
      }
    });
  },
  ////// определяет, какие поля обязательные для формирования лицензии и валидирует их
  [DEFINE_LICENSE_FIELDS](state: any) {
    const licenseFields = ["name", "surname", "email", "phone"];

    licenseFields.forEach((controlName: string) => {
      state.formControls[controlName].required = true;
      state.formControls[controlName].rules = { empty: true };

      if (controlName === "email") {
        state.formControls[controlName].rules.email = true;
      }

      if (controlName === "phone") {
        state.formControls[controlName].rules.minLength = 10;
      }
    });
  },
  [NOT_REQUIRED_LICENSE_FIELDS](state: any, {subjectType}: {subjectType: TTypeEntreName}) {
    const typeEntreObj = TYPE_ENTRES.find((t :any) => t.name === subjectType);
    const notRequiredFieldsLicense = typeEntreObj?.notRequiredFieldsLicense ?? [];

    notRequiredFieldsLicense.forEach((key) => {
      const formControls = state.formControls[key] as IField;

      formControls.required = false;

      if (formControls.rules) {
        formControls.rules.empty = false;

        if ('snils' in formControls.rules) formControls.rules.snils = false;
        if ('ogrnip' in formControls.rules) formControls.rules.ogrnip = false;
        if ('ogrn' in formControls.rules) formControls.rules.ogrn = false;
      }
    });
  },

  ////// определяет, какие поля обязательны для выдачи токена
  [DEFINE_TOKEN_FIELDS](state: any) {
    const tokenFields = [
      "name", 
      "surname", 
      "birthDate", 
      "email", 
      "phone", 
      "snils", 
      "inn", 
      "countryId",
      "cityReal",
    ];

    tokenFields.forEach((controlName: string) => {
      state.formControls[controlName].required = true;
      state.formControls[controlName].rules = { empty: true };

      if (controlName === "birthDate") {
        state.formControls[controlName].rules.date = true;
      }

      if (controlName === "email") {
        state.formControls[controlName].rules.email = true;
      }

      if (controlName === "phone") {
        state.formControls[controlName].rules.minLength = 10;
      }

      if (controlName === "snils") {
        state.formControls[controlName].rules.snils = true;
        state.formControls[controlName].rules.minLength = 11;
      }

      if (controlName === "inn") {
        state.formControls[controlName].rules.inn = true;
        state.formControls[controlName].rules.minLength = 10;
      }
    });
  },

  [NOT_REQUIRED_TOKEN_FIELDS](state: any, {subjectType}: {subjectType: TTypeEntreName}) {
    const typeEntreObj = TYPE_ENTRES.find((t :any) => t.name === subjectType);
    const notRequiredFieldsToken = typeEntreObj?.notRequiredFieldsToken ?? [];

    notRequiredFieldsToken.forEach((key) => {
      const formControls = state.formControls[key] as IField;

      formControls.required = false;

      if (formControls.rules) {
        formControls.rules.empty = false;

        if (key === "gender" && formControls.rules.gender) {
          formControls.rules.gender = false;
        }
      }
    });
  },
  [SET_CALENDAR_APPOINTMENT](state, calendarAppointment: ICalendarAppointment | null) {
    state.calendarAppointment = calendarAppointment;
  },
};