import { MutationTree } from 'vuex';

import { ICalendarState, ICalendarAppointment } from './calendar.types';

export const SET_CALENDAR_DATA = 'SET_CALENDAR_DATA';
export const ADD_CALENDAR_APPOINTMENT = 'ADD_CALENDAR_APPOINTMENT';
export const SET_CALENDAR_APPOINTMENTS = 'SET_CALENDAR_APPOINTMENTS';
export const DELETE_CALENDAR_APPOINTMENT = 'DELETE_CALENDAR_APPOINTMENT';
export const SET_CALENDAR_NEW_APPOINTMENT = 'SET_CALENDAR_NEW_APPOINTMENT';
export const SET_CALENDAR_CURRENT_APPOINTMENT = 'SET_CALENDAR_CURRENT_APPOINTMENT';

export const mutations: MutationTree<ICalendarState> = {
  [SET_CALENDAR_DATA](state, calendarData: ICalendarState['calendarData']) {
    state.calendarData = calendarData;
  },
  [SET_CALENDAR_APPOINTMENTS](state, allAppointments: ICalendarState['allAppointments']) {
    state.allAppointments = allAppointments;
  },
  [ADD_CALENDAR_APPOINTMENT](state, {
    appointmentKey,
    newAppointment,
  }: {
    appointmentKey: string;
    newAppointment: ICalendarAppointment;
  }) {
    const allAppointments = JSON.parse(JSON.stringify(state.allAppointments));
    if (allAppointments[appointmentKey]) {
      allAppointments[appointmentKey].push(newAppointment);
    } else {
      allAppointments[appointmentKey] = [newAppointment];
    }
    state.allAppointments = allAppointments;
  },
  [DELETE_CALENDAR_APPOINTMENT](state, { appointmentKey, id }: { appointmentKey: string; id: number }) {
    const allAppointments = JSON.parse(JSON.stringify(state.allAppointments));
    if (allAppointments[appointmentKey] && allAppointments[appointmentKey].length > 1) {
      const index = allAppointments[appointmentKey].findIndex((a: {id: number}) => a.id === id);
      allAppointments[appointmentKey].splice(index, 1);
    } else {
      delete allAppointments[appointmentKey];
    }
    state.allAppointments = allAppointments;
  },
  [SET_CALENDAR_NEW_APPOINTMENT](state, calendarNewAppointment: ICalendarAppointment) {
    state.calendarNewAppointment = calendarNewAppointment;
  },
  [SET_CALENDAR_CURRENT_APPOINTMENT](state, calendarCurrentAppointment: ICalendarAppointment) {
    state.calendarCurrentAppointment = calendarCurrentAppointment;
  },
};
