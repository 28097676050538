import {app} from '@/main';
import { fetch } from '@/services/api';
import { CALENDAR_URLS as URL_LIST } from '@/config/urls';
import {
  IGetCalendarByFilialIdResponse,
  IGetAppointmentByOrderIdResponse,
} from './calendar.types';
import { CALENDAR_APPLICATION_TYPES } from './calendar.config';

const getCalendarByFilialId = async ({
  from,
  to,
}: {
  from: string; // dd.MM.yyyy
  to: string; // dd.MM.yyyy
}) => {
  const response: { data?: IGetCalendarByFilialIdResponse; error?: string } = {};

  app.$modal.show('loader');

  try {
    const { data, status } = await fetch.get(
      `${URL_LIST.GetCalendarByFilialId}?showtimeslots=detailed&from=${from}&to=${to}`,
    );

    if (!status || status !== 200)
      throw new Error('Неожиданный статус запроса');
    if (data && data.errors) throw new Error(data.errors.map(({ message }: { message: string }) => message).join('; '));

    response.data = data;
  } catch (err) {
    const error = err as any;
    response.error = JSON.stringify(
      error.response?.data || error.message || 'Неизвестная ошибка',
    );
  } finally {
    app.$modal.hide('loader');
  }

  return response;
};

const getAppointmentByOrderId = async ({
  orderId,
  applicationType, // заявка 1, предзаявка 2
}: {
  orderId: number;
  applicationType: CALENDAR_APPLICATION_TYPES;
}) => {
  const response: {
    data?: IGetAppointmentByOrderIdResponse;
    error?: string;
    status?: number;
  } = {};

  try {
    const { data, status } = await fetch.get(
      `${URL_LIST.GetAppointments}/${orderId}?applicationType=${applicationType}`,
    );

    // 204 is appointment not found
    if (!status || (status !== 200 && status !== 204))
      throw new Error('Неожиданный статус запроса');
    if (data && data.errors) throw new Error(data.errors.map(({ message }: { message: string }) => message).join('; '));

    response.data = data;
    response.status = status;
  } catch (err) {
    const error = err as any;
    response.error = JSON.stringify(
      error.response?.data || error.message || 'Неизвестная ошибка',
    );
  }

  return response;
};

const changeAppointment = async ({
  orderId,
  applicationType, // заявка 1, предзаявка 2
  officeId,
  newAp,
}: {
  orderId: number;
  applicationType: CALENDAR_APPLICATION_TYPES;
  officeId: number;
  newAp: {
    date: string;
    time: string;
    name: string;
    phone: string;
    email: string;
    comment: string;
  };
}) => {
  const response: {
    error?: string;
  } = {};

  app.$modal.show('loader');

  try {
    const { data, status } = await fetch.put(
      `${URL_LIST.PutChangeAppointment}/${orderId}?applicationType=${applicationType}`,
      {
        ApplicationId: orderId,
        ApplicationType: applicationType,
        VisitDate: newAp.date,
        VisitTime: newAp.time,
        ClientName: newAp.name,
        ClientPhone: newAp.phone,
        ClientEmail: newAp.email,
        Comment: newAp.comment,
        OfficeId: officeId,
      },
    );

    if (!status || status !== 200)
      throw new Error('Неожиданный статус запроса');
    if (data && data.errors) throw new Error(data.errors.map(({ message }: { message: string }) => message).join('; '));
  } catch (err) {
    const error = err as any;
    response.error = JSON.stringify(
      error.response?.data || error.message || 'Неизвестная ошибка',
    );
  } finally {
    app.$modal.hide('loader');
  }

  return response;
};

const addAppointment = async ({
  orderId,
  applicationType, // заявка 1, предзаявка 2
  officeId,
  newAp,
}: {
  orderId: number;
  applicationType: CALENDAR_APPLICATION_TYPES;
  officeId: number;
  newAp: {
    date: string;
    time: string;
    name: string;
    phone: string;
    email: string;
    comment: string;
  };
}) => {
  const response: {
    error?: string;
  } = {};

  app.$modal.show('loader');

  try {
    const { data, status } = await fetch.post(
      `${URL_LIST.PostAddAppointment}`,
      {
        ApplicationId: orderId,
        ApplicationType: applicationType,
        VisitDate: newAp.date,
        VisitTime: newAp.time,
        ClientName: newAp.name,
        ClientPhone: newAp.phone,
        ClientEmail: newAp.email,
        Comment: newAp.comment,
        OfficeId: officeId,
      },
    );

    if (!status || (status !== 200 && status !== 201))
      throw new Error('Неожиданный статус запроса');
    if (data && data.errors) throw new Error(data.errors.map(({ message }: { message: string }) => message).join('; '));
  } catch (err) {
    const error = err as any;
    response.error = JSON.stringify(
      error.response?.data || error.message || 'Неизвестная ошибка',
    );
  } finally {
    app.$modal.hide('loader');
  }

  return response;
};

const deleteAppointment = async ({
  orderId,
  applicationType, // заявка 1, предзаявка 2
}: {
  orderId: number;
  applicationType: CALENDAR_APPLICATION_TYPES;
}) => {
  const response: {
    error?: string;
  } = {};

  app.$modal.show('loader');

  try {
    const { data, status } = await fetch.delete(
      `${URL_LIST.DeleteAppointment}/${orderId}?applicationType=${applicationType}`,
    );

    if (!status || status !== 200)
      throw new Error('Неожиданный статус запроса');
    if (data && data.errors) throw new Error(data.errors.map(({ message }: { message: string }) => message).join('; '));
  } catch (err) {
    const error = err as any;
    response.error = JSON.stringify(
      error.response?.data || error.message || 'Неизвестная ошибка',
    );
  } finally {
    app.$modal.hide('loader');
  }

  return response;
};

export default {
  getCalendarByFilialId,
  getAppointmentByOrderId,
  changeAppointment,
  deleteAppointment,
  addAppointment,
};
