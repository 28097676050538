export enum REVOKE_URLS {
  GetApplication = 'api/CertificateRevoke/GetByNumber',
  DownloadDocument = 'api/CertificateRevoke/GetDocument',
  AttachDocument = 'api/CertificateRevoke/AttachDocument',
  AttachSignature = 'api/CertificateRevoke/AttachSignature',
  GetVerifyingCode = 'api/CertificateRevoke/Verify/SendCode',
  SkipVerifyingCode = 'api/CertificateRevoke/Verify/Skip',
  CheckVerifyingCode = 'api/CertificateRevoke/Verify/CheckCode',
};

export enum REVOKE_LIST_URLS {
  GetList = 'api/CertificateRevoke/GetListForFilial',
  GetReport = 'api/CertificateRevoke/report',
  GetCertificatesByFilter = 'api/CertificateRevoke/GetCertificatesByFilter',
  CreateNewRevoke = 'api/CertificateRevoke/Create',
  GetFilialByManager = 'api/CertificateRevoke/GetFilialByManager',
};

export enum CERTIFICATE_LIST_URLS {
  GetList = 'api/FilialCerts/GetListByFilter',
  GetReport = '',
  Validate = 'api/FilialCerts/Validate',
}

export enum FILTER_LIST_URLS {
  GetAppProducts = 'api/initialApplication/getProducts',
  GetPreappProducts = 'api/initialApplication/getProducts', // тот же, что для заявок, да
}

export enum PREAPPLICATION_URLS {
  GetFieldsFromBasis = '/api/initialApplication/getFieldsFromBasis/',
  ReadyForUpd = 'api/InternalPreApplication/readyForUpd',
  SetStatus = '/api/InternalPreApplication/setStatus',
}

export enum APPLICATION_URLS {
  GetApplicationInfo = '/api/initialApplication',
  SaveCompanyGroup = '/api/initialApplication/groupCompany/createOrGet',
  CompanyGroupList = '/api/initialApplication/groupCompany/list',
  SaleLicense = '/api/initialApplication/saleLicense',
  GetLicensePdf = '/api/initialApplication/license/pdf',
}

export enum APPLICATION_LIST_URLS {
  GetApplicationList = '/api/initialApplication',
  UploadXmlWithApps = '/api/InitialApplication/CreateFromXml',
}

export enum SMS_URLS {
  GetPreappSmsList = '/api/PreappSms/list',
  SendPreappSms = '/api/PreappSms/send',
}

export enum EXTERNAL_SERVICES_URLS {
  GetDivisionName = 'api/dadata/fms_unit',
}

export enum CALENDAR_URLS {
  GetCalendarByFilialId = '/api/calendar',
  GetAdditionalData = '/api/CalendarAdditional/getOrganizationsWithRegion',
  GetAppointments = 'api/appointments',
  PutChangeAppointment = 'api/appointments',
  DeleteAppointment = 'api/appointments',
  PostAddAppointment = 'api/appointments',
}
