import { Module } from 'vuex';

import { RootState } from '@/store/root.interface';

import { actions } from './calendar.actions';
import { getters } from './calendar.getters';
import { mutations } from './calendar.mutations';
import { ICalendarState } from './calendar.types';

export const calendarState: Module<ICalendarState, RootState> = {
  namespaced: true,
  state: {
    allAppointments: {},
    calendarData: [],
    calendarNewAppointment: null,
    calendarCurrentAppointment: null,
  },
  mutations,
  actions,
  getters
};
