import { isEmpty } from "lodash";

export interface IRulesValidation {
  empty?: boolean;
  email?: boolean;
  phone?: boolean;
  inn?: boolean;
  ogrnip?: boolean;
  date?: boolean;
  ogrn?: boolean;
  numeric?: boolean;
  alpha?: boolean;
  uppercase?: boolean;
  minLength?: number;
  maxLength?: number;
  snils?: boolean;
  gender?: boolean;
  cyrillic?: boolean;
}

const reg = {
  email: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,

  phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
};

const ogrnip = (val: number | string): boolean => {
  const value = val.toString();
  if (!/^\d{15}$/.test(value)) {
    return false;
  }
  return (
    // tslint:disable-next-line: radix
    parseInt((Number(value.slice(0, -1)) % 13).toString().slice(-1)) ===
    +value[14]
  );
};

const ogrn = (val: number | string): boolean => {
  const value = val.toString();
  if (!/^\d{13}$/.test(value)) {
    return false;
  }
  return (
    // tslint:disable-next-line: radix
    parseInt((Number(value.slice(0, -1)) % 11).toString().slice(-1)) ===
    +value[12]
  );
};

const inn = (val: number | string): boolean => {
  const value = val.toString();

  if (!value) return true;

  const valArr: string[] = value.split("");
  if (!/^\d{10}$/.test(value) && !/^\d{12}$/.test(value)) {
    return false;
  }

  if (
    valArr.length === 10 &&
    +valArr[9] ===
      ((2 * +valArr[0] +
        4 * +valArr[1] +
        10 * +valArr[2] +
        3 * +valArr[3] +
        5 * +valArr[4] +
        9 * +valArr[5] +
        4 * +valArr[6] +
        6 * +valArr[7] +
        8 * +valArr[8]) %
        11) %
        10
  ) {
    return true;
  }

  return (
    valArr.length === 12 &&
    +valArr[10] ===
      ((7 * +valArr[0] +
        2 * +valArr[1] +
        4 * +valArr[2] +
        10 * +valArr[3] +
        3 * +valArr[4] +
        5 * +valArr[5] +
        9 * +valArr[6] +
        4 * +valArr[7] +
        6 * +valArr[8] +
        8 * +valArr[9]) %
        11) %
        10 &&
    +valArr[11] ===
      ((3 * +valArr[0] +
        7 * +valArr[1] +
        2 * +valArr[2] +
        4 * +valArr[3] +
        10 * +valArr[4] +
        3 * +valArr[5] +
        5 * +valArr[6] +
        9 * +valArr[7] +
        4 * +valArr[8] +
        6 * +valArr[9] +
        8 * +valArr[10]) %
        11) %
        10
  );
};

type Validation = {
  isValid: boolean,
  invalidityInfo: string
};

type RD = {[key: string]: (val?: any) => string};

const RULES_DESCRIPTION: RD = {
  empty: () => `Поле не должно быть пустым`,
  email: () => `Некорректный формат электронной почты`,
  phone: () => `Некорректный формат номера телефона`,
  inn: () => `Некорректное значение ИНН`,
  ogrnip: () => `Некорректное значение ОГРН`,
  date: () => `Некорректный формат даты`,
  ogrn: () => `Некорректное значение ОГРН`,
  numeric: () => `Поле должно содержать цифры`,
  alpha: () => `Поле должно содержать латинские буквы от a до z в нижнем регистре`,
  uppercase: () => `Поле должно содержать латинские буквы от A до Z в верхнем регистре`,
  minLength: (val: number) => `Поле должно содержать не менее ${val} символов`,
  maxLength: (val: number) => `Поле должно содержать не более ${val} символов`,
  snils: () => `Некорректное значение СНИЛС`,
  gender: () => `Пол не может быть неопределённым`,
  cyrillic: () => 'Поле не может содержать буквы латинского алфавита',
};

export default (val: string, rules: IRulesValidation): Validation => {
  if (!rules) {
    return {isValid: true, invalidityInfo: ""};
  }

  const invalidityDescr: any[] = [];

  function addDescr(valid: boolean, ruleName: string, val?: any) {
    if(!valid) {
      invalidityDescr.push(RULES_DESCRIPTION[ruleName](val));
    }
  }

  let isValid = false;

  if (rules.empty) {
    let valid;
    if (!val) {
      valid = false; // case if val is null or undefined
    } else {
      valid = !isEmpty(val.toString());
    } 
    addDescr(valid, 'empty');
    isValid = valid;
  }
  else {
    isValid = true;
  }

  if (isValid) {
    if (rules.email) {
      const valid = reg.email.test(val.trim().toLowerCase());
      addDescr(valid, 'email');
      isValid = valid && isValid;
    }
  
    if (rules.phone) {
      const valid = reg.phone.test(val);
      addDescr(valid, 'phone');
      isValid = valid && isValid;
    }
  
    if (rules.inn) {
      const valid = inn(val);
      addDescr(valid, 'inn');
      isValid = valid && isValid;
    }
  
    if (rules.ogrnip) {
      const valid = ogrnip(val);
      addDescr(valid, 'ogrnip');
      isValid = valid && isValid;
    }
  
    if (rules.date) {
      const valid = /^[0-9.]*$/.test(val);
      addDescr(valid, 'date');
      isValid = valid && isValid;
    }
  
    if (rules.ogrn) {
      const valid = ogrn(val);
      addDescr(valid, 'ogrn');
      isValid = valid && isValid;
    }
  
    if (rules.numeric) {
      const valid = /[0-9]/g.test(val.toString());
      addDescr(valid, 'numeric');
      isValid = valid && isValid;
    }
  
    if (rules.alpha) {
      const valid = /[a-z]/g.test(val.toString());
      addDescr(valid, 'alpha');
      isValid = valid && isValid;
    }
  
    if (rules.uppercase) {
      const valid = /[A-Z]/g.test(val.toString());
      addDescr(valid, 'uppercase');
      isValid = valid && isValid;
    }
  
    if (rules.empty && rules.minLength) {
      const valid = rules.minLength <= val.toString().length;
      addDescr(valid, 'minLength', rules.minLength);
      isValid = valid && isValid;
    }
  
    if (!rules.empty && rules.minLength) {
      const valid = (isEmpty(val.toString()) || rules.minLength <= val.toString().length);
      addDescr(valid, 'minLength', rules.minLength);
      isValid = valid && isValid;
    }
  
    if (rules.maxLength) {
      const valid = rules.maxLength > val.toString().length;
      addDescr(valid, 'maxLength', rules.maxLength);
      isValid = valid && isValid;
    }
  
    if (rules.snils) {
      let check = snils(val);
      const valid = check;
      addDescr(valid, 'snils'); 
      isValid = valid && isValid;
    }

    if (rules.gender) {
      const valid = val !== 'U';
      addDescr(valid, 'gender'); 
      isValid = valid && isValid;
    }

    if (rules.cyrillic) {
      const valid = !(/[a-zA-Z]/g.test(val.toString()));
      addDescr(valid, 'cyrillic');
      isValid = valid && isValid;
    }
  }

  const invalidityInfo: string = invalidityDescr.join('. ');

  return { isValid, invalidityInfo };
};

const snils = (val: number | string): boolean => {
  const value = val.toString();
  const snils = String(value).replace(/[^0-9]+/g, '');
  if (snils.length == 11) {
    let checksum = 0;
    for (let i = 0; i < 9; i++) {
      checksum += parseInt(snils.charAt(i)) * (9 - i);
    }
    if (checksum > 101) {
      checksum = checksum % 101;
    }
    if (checksum == 100 || checksum == 101) {
      checksum = 0;
    }
    return checksum == parseInt(snils.substr(9));
  }
  return false;
}

export const isFormValid = (controls: any) => {
  const errors: string[] = [];

  Object.keys(controls).forEach((key: string) => {
    const control = { ...controls[key] };
    if (!control.isBlur || (control.required && !control.isValid)) {
      errors.push(key);
    }
  });

  return errors.length === 0;
};

export const isCheckboxValid = (controls: any, id: number) => {
  const result: string[] = [];

  Object.keys(controls).forEach((key: string) => {
    const control = { ...controls[key] };

    if (control.checkboxId === id && control.value) {
      result.push(key);
    }
  });

  return !!result.length;
};
